import React from "react";
import { Card, Col, Empty, Layout, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import forecatService from "../services/forecatService";
import { isEmpty, toString } from "lodash";
import { Loader } from "../components/loader";
import { DomaineForecast } from "./forecat/domaine";

export function ForecatView() {
  const [forecat, setForecat] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [params] = useSearchParams();

  const init = React.useCallback(async () => {
    const uid = params.get("v");
    setLoading(true);
    await forecatService
      .get(toString(uid))
      .then((response) => {
        const data = response.data;
        setForecat(data);
      })
      .catch(() => {
        console.warn("dsd");
      });
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  React.useEffect(() => {
    init();
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {loading && <Loader />}
      {!loading && !isEmpty(forecat["@id"]) && (
        <div>
          <DomaineForecast forecat={forecat} />
          <Row
            gutter={32}
            align="stretch"
            style={{ padding: 15, background: "#f7f7f7" }}
          >
            <Col md={12} xs={24}>
              <Card className="yc-card yc-card-margin">
                <Card.Meta
                  title="Resumé de la leçon"
                  description={isEmpty(forecat.summary) ? "Aucun resumé" : ""}
                />
                {!isEmpty(forecat.summary) && (
                  <div
                    style={{ marginTop: 10 }}
                    dangerouslySetInnerHTML={{
                      __html: forecat.summary?.replace(/\n/g, "<br>"),
                    }}
                  />
                )}
              </Card>
            </Col>
            <Col md={12} xs={24}>
              <Card className="yc-card yc-card-margin">
                <Card.Meta
                  title="Objectif de la leçon"
                  description={
                    !isEmpty(forecat.purpose)
                      ? ""
                      : "Aucun objectif n'est signalé."
                  }
                />
                {!isEmpty(forecat.purpose) && (
                  <div
                    style={{ marginTop: 10 }}
                    dangerouslySetInnerHTML={{
                      __html: forecat.purpose?.replace(/\n/g, "<br>"),
                    }}
                  />
                )}
              </Card>
            </Col>
            <Col md={12} xs={24}>
              <Card className="yc-card yc-card-margin">
                <Card.Meta
                  title="Autres contenus à consulter"
                  description={
                    !isEmpty(forecat.activities)
                      ? ""
                      : "Aucune activité n'est signalé."
                  }
                />
                {!isEmpty(forecat.activities) && (
                  <div
                    style={{ marginTop: 10 }}
                    dangerouslySetInnerHTML={{
                      __html: forecat.activities?.replace(/\n/g, "<br>"),
                    }}
                  />
                )}
              </Card>
            </Col>
            <Col md={12} xs={24}>
              <Card className="yc-card yc-card-margin">
                <Card.Meta
                  title="Les exercices"
                  description={
                    isEmpty(forecat.generalCulture) ? "Aucun exercice" : ""
                  }
                />
                {!isEmpty(forecat.generalCulture) && (
                  <div
                    style={{ marginTop: 10 }}
                    dangerouslySetInnerHTML={{
                      __html: forecat.generalCulture?.replace(/\n/g, "<br>"),
                    }}
                  />
                )}
              </Card>
            </Col>
            {/*<Col md={24} xs={24}>
              <Card className="yc-card yc-card-margin">
                <Card.Meta
                  title="Références"
                  description={
                    "Aucune référence n'est rattachée à cette prévision"
                  }
                  style={{ textAlign: "center" }}
                />
              </Card>
                </Col>*/}
          </Row>
        </div>
      )}
      {!loading && isEmpty(forecat["@id"]) && <Empty />}
    </Layout>
  );
}
