import { Col, Layout, Row, Typography } from "antd";
import { upperCase, upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { CreateSubscription } from "../components/createSubscription";
import levelHelper from "../utils/helpers/levelHelper";
import { CardProfil } from "./profil/card";
import { DataProfil } from "./profil/data";
import { HeaderProfil } from "./profil/header";

const { Text, Title } = Typography;
export function ProfilScreen() {
  const user = useSelector((state) => state.user.profil);
  const level = useSelector((state) => state.level.profil);
  return (
    <Layout>
      <HeaderProfil>
        <Row
          gutter={16}
          align="middle"
          className="yc-profil-showing"
          justify="end"
          style={{ width: "100%", padding: "10px" }}
        >
          <Col flex={1}>
            <Title style={{ margin: 0, color: "#fff", fontSize: 28 }}>
              {`${upperFirst(user.firstName)} ${upperCase(user.lastName)}`}{" "}
            </Title>
            <Text style={{ color: "#fff" }}>
              {levelHelper.getClass(level.title, level.groups)}
            </Text>
          </Col>
          <Col>
            <CreateSubscription title="S'abonner" user={user} payer={user} />
          </Col>
        </Row>
      </HeaderProfil>
      <div style={{ background: "#fff" }}>
        <div className="yc-profil-user-fixed">
          <CardProfil user={user} level={level} />
        </div>
        <div>
          <DataProfil user={user} type="profil" level={level} />
        </div>
      </div>
    </Layout>
  );
}
