import { isEmpty, random, toUpper } from "lodash";
import relationshipValues from "../../relationships";
import { paymentType } from "./interface";
import { routeInterface, routes } from "../../routes";

const appName = "yeccolapp";
const refKey = "yecc-ref";

const setAppTitle = (
  value: string,
  description?: string,
  //canonical?: string
) => {
  document.title = `${appName}${!isEmpty(value) ? " | " : ""}${value}`;
  if (!isEmpty(description)) {
    document.getElementsByName("description").forEach((p) => {
      if (toUpper(p.nodeName) === "META") {
        p.setAttribute("content", description || "YECCOLAPP");
      }
    });
  }
};

const getUid = (value: string): string => {
  if (typeof value !== "string") {
    return "";
  }
  if (isEmpty(value)) {
    return "";
  }
  let response = "";
  const items = value.split("/");
  if (items.length > 0) {
    response = items[items.length - 1];
  }
  return response;
};

export function cleanHTML(str: string) {
  let response = "";
  if (!isEmpty(str)) {
    str = str.toString();
    response = str.replace(/<[^>]*>/g, "");
  }
  return response;
}
export function getLessonPoster(files: any[]) {
  const index = random(false);
  const images = files.filter((p) => p.typeMime.search("image") != -1);
  let response = `https://picsum.photos/800/600?random=${index}`;
  if (images.length > 0) {
    response = images[0].file;
  }
  return response;
}
export function getRelationship(
  value: string,
  lang: "fr" | "eng",
  isChild: boolean,
) {
  let response = "Autres relations";
  const list = relationshipValues;
  const item = list.find((p) => p.value === value);
  if (isChild) {
    if (item !== undefined) {
      const element = item[lang];
      if (element === undefined) {
        response = item.title;
      } else {
        response = element;
      }
    }
  }
  return response;
}
export function getPaymentList() {
  const data: paymentType[] = [
    "machine",
    "schoolBook",
    "schoolKits",
    "subscription",
    "womenContest",
  ];
  return data;
}
export function getPaymentListTitle(): { title: string; value: paymentType }[] {
  const list = getPaymentList();
  const data: any[] = [];
  list.forEach((p) => {
    switch (p) {
      case "schoolBook":
        data.push({ title: "Commande d'un ouvrage scolaire", value: p });
        break;
      case "womenContest":
        data.push({ title: "Concours féminin d'éloquence", value: p });
        break;
      case "schoolKits":
        data.push({ title: "Kits scolaires", value: p });
        break;
      case "machine":
        data.push({
          title: "Souscription Tablettes et ordinateurs YECCOLAPP",
          value: p,
        });
        break;
      case "subscription":
        data.push({ title: "Abonnement", value: p });
        break;
      default:
        data.push({ title: "Non défini", value: p });
        break;
    }
  });

  return data;
}
export function getPaymentTypeValue(type: string) {
  const items = getPaymentListTitle();
  const item = items.find((p) => p.value == type);
  if (!isEmpty(item)) {
    return item?.title ?? "Non défini";
  } else {
    return "Non défini";
  }
}

export function getCurrentRoute(pathname: string): routeInterface | undefined {
  let route: any;
  try {
    route = routes.find(
      (p) => pathname === `${p.layout}${p.path === "/" ? "" : p.path}`,
    );
  } catch (error) {
    console.warn("Error", error);
  }
  return route;
}
export { appName, getUid, refKey, setAppTitle };
