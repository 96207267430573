import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DlSelect } from "../../components/input";
import * as yup from "yup";
import userService from "../../services/userService";
import { getUid } from "../../utils/helpers/title";
import { toString } from "lodash";
import userHelper from "../../utils/helpers/userHelper";
import { useDispatch } from "react-redux";
import studentService from "../../services/studentService";
import etsService from "../../services/etsService";

interface updateUserInterface {
  user: any;
}

export function UpdateSchool(props: updateUserInterface = { user: {} }) {
  const user = props.user;

  React.useEffect(() => {
    const { establishment } = user.student;
    formik.setValues({ establishment });
  }, []);

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [items, setItems] = React.useState<any[]>([]);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      establishment: props.user.student?.establishment,
    },
    onSubmit: handleUpdate,
    validationSchema: yup.object({
      establishment: yup.string().required("Champ requis"),
    }),
  });
  async function handleUpdate() {
    const data = formik.values;
    const uid = getUid(user.student["@id"]);
    setLoading(true);
    await studentService
      .update(toString(uid), data)
      .then(async (response) => {
        const data = response.data;
        const uid = getUid(data.users["@id"]);
        await getUser(uid);
        setLoading(false);
        setVisible(false);
        formik.resetForm();
      })
      .catch((reason) => console.warn(reason));
    setLoading(false);
  }
  async function getUser(uid: string) {
    await userService
      .get(uid)
      .then((response) => {
        const data = response.data;
        userHelper.setData(data, dispatch);
        notification.info({
          message: "Modifcation de nom",
          description: "La modificatio a réussi",
        });
      })
      .catch((reason) => console.warn(reason));
  }
  function handleOpen() {
    setVisible(true);
  }
  function handleClose() {
    if (loading) {
      return;
    }
    setVisible(false);
    formik.handleReset(() => {
      console.log("");
    });
  }

  async function searchEstablishment(value: string) {
    setRefresh(true);
    const elements: any[] = [
      { title: "Séléctionner un établissement", value: "" },
    ];
    await etsService
      .getByKey(`name=${value}&order[name]=asc`)
      .then((response) => {
        const data = etsService.getData(response);
        data.forEach((p: any) => {
          elements.push({
            value: p.name,
            title: p.name,
          });
        });
      })
      .catch((reason) => {
        console.warn(reason);
      });
    setItems(elements);
    setRefresh(false);
  }

  React.useEffect(() => {
    searchEstablishment(search);
  }, [search]);

  return (
    <React.Fragment>
      <Button onClick={handleOpen} style={{ fontSize: 14 }} type="link">
        Modifier
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        visible={visible}
        onCancel={handleClose}
        okText="Modifier"
        cancelText="Annuler"
        centered
        onOk={() => formik.handleSubmit()}
        title="Modifier de l'établissement"
        confirmLoading={loading}
      >
        <div>
          <DlSelect
            formik={formik}
            search
            setSearch={setSearch}
            name="establishment"
            placeholder="votre établissement"
            label="Votre établissement"
            list={items}
            loading={refresh}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
