import { Button, Col, Layout, List, Row, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRelationship, getUid } from "../../utils/helpers/title";
import { RootState } from "../../redux/store";
import { isEmpty, toUpper, upperFirst } from "lodash";
import { UserAvatar } from "../../components/card/userAvatar";
import { CreateNote } from "../teacher/createNote";

interface propInterface {
  items?: any[];
  type?: "responsible" | "teacher";
}

export function ChildrenResponsible(
  props: propInterface = { type: "responsible" }
) {
  const students = useSelector((state: RootState) => state.support.children);
  const [page, setPage] = React.useState(1);

  const renderItem = (item: any) => {
    const student = item?.student;
    let uid = null;
    if (!isEmpty(student)) {
      uid = student["@id"];
    }
    if (isEmpty(uid)) {
      return null;
    }
    return (
      <List.Item>
        <UserAvatar
          title={`${upperFirst(item?.student?.users?.firstName)} ${toUpper(
            item?.student?.users?.lastName
          )}`}
          subtitle={`${getRelationship(item.relationship, "fr", true)}`}
          photo={item?.student?.users?.photo}
          buttons={
            props.type === "teacher"
              ? [
                  item.status === "validated" && (
                    <CreateNote management={item} />
                  ),
                  <Link
                    key={`see${item.uid}`}
                    to={`/admin/child/view?v=${getUid(uid)}`}
                  >
                    <Button>Voir</Button>
                  </Link>,
                ]
              : []
          }
        />
      </List.Item>
    );
  };

  return (
    <Layout
      style={{
        backgroundColor: "transparent",
        margin: "50px 10px",
        padding: "30px 10px",
      }}
      className="dl-layout-stretch"
    >
      <Row align="middle" gutter={8} style={{ padding: "10px 30px" }}>
        <Col flex={1}>
          <Typography.Title level={2} style={{ margin: 0 }}>
            Les étudiants
          </Typography.Title>
          <Typography.Text>
            Liste des étudiants à votre disposition
          </Typography.Text>
        </Col>
      </Row>
      <List
        dataSource={props.type === "responsible" ? students : props.items ?? []}
        renderItem={renderItem}
        grid={{ xl: 4, xxl: 4, lg: 4, sm: 2, xs: 1, md: 2, gutter: 8 }}
        pagination={{
          total: students.length,
          pageSize: 8,
          current: page,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </Layout>
  );
}
