import React from "react";
import { Menu } from "antd";
import { routes } from "../../routes";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ToolNavbar } from "./tool";
import { ProfilMenu } from "../../screens/profil/menu";
import { isEmpty, toUpper } from "lodash";
import { SearchbarNav } from "./searchbar";
import { useSelector } from "react-redux";
import userHelper from "../../utils/helpers/userHelper";
import { ModalSearch } from "./modalSearch";

//const SubMenu = Menu.SubMenu;
//const MenuItemGroup = Menu.ItemGroup;

function LeftMenu({ mode = "horizontal", current = "" }) {
  const [active, setActive] = React.useState(true);
  const user = useSelector((state) => state.user.profil);
  console.log(active);

  return (
    <Menu mode={mode} className="yc-navbar">
      {active && (
        <>
          {routes
            .filter(
              (p) =>
                p.layout === "/admin" &&
                p.isMenu &&
                userHelper.isVisible(p.visibility, user, false, false)
            )
            .map((p, index) => {
              if (isEmpty(p.parent)) {
                return (
                  <Menu.Item key={`${p.name}${index}`}>
                    <Link
                      className={`${
                        p.layout + p.path === current ? "yc-active" : ""
                      }`}
                      to={`${p.layout}${p.path}`}
                    >
                      {toUpper(p.label)}
                    </Link>
                  </Menu.Item>
                );
              } else {
                return (
                  <Menu.SubMenu key={p.parent} title={p.parent}>
                    {routes
                      .filter(
                        (x) => x.layout === "/admin" && x.parent === p.parent
                      )
                      .map((p, index) => {
                        return (
                          <Menu.Item key={`${p.name}${index}`}>
                            <Link
                              className={`${
                                p.layout + p.path === current ? "yc-active" : ""
                              }`}
                              to={`${p.layout}${p.path}`}
                            >
                              {toUpper(p.label)}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                  </Menu.SubMenu>
                );
              }
            })}
        </>
      )}
      {user === "ff" && (
        <SearchbarNav placeholder="Chercher une leçon" setActive={setActive} />
      )}
      <ModalSearch />
      <ToolNavbar />
      <ProfilMenu />
    </Menu>
  );
}

LeftMenu.propTypes = {
  mode: PropTypes.string,
  current: PropTypes.string,
};

export default LeftMenu;
