import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DlInput } from "../../components/input";
import * as yup from "yup";
import { phoneRegex } from "./../login";
import studentService from "../../services/studentService";
import { getUid } from "../../utils/helpers/title";
import supportService from "../../services/supportService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helpers/userHelper";
import { toString } from "lodash";
import propTypes from "prop-types";
import { UsergroupAddOutlined } from "@ant-design/icons";
import teacherService from "../../services/teacherService";
import managementService from "../../services/managementService";
import { setTeacherStudents } from "../../redux/teacherReducer";
import { setStudentTeachers } from "../../redux/studentReducer";

export function CreateStudent({
  type = "default",
  isResponsible = true,
  init = {},
}) {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profil);
  const dispatch = useDispatch();
  const regex =
    /(^[+][(]{0,1}[0-9]{1,4}[)]{0,1}([-\s\./0-9]{6,10})|^YEC-[0-9]{1,4}-[0-9]{1,4})$/; //eslint-disable-line no-useless-escape

  const formik = useFormik({
    initialValues: { relationship: "", phone: "" },
    onSubmit: handleCreate,
    validationSchema: yup.object({
      phone: yup
        .string()
        .required("Le champ est requis")
        .matches(isResponsible ? regex : phoneRegex, {
          message: "Ecrivez correctement votre numéro",
        }),
    }),
  });

  function handleShow() {
    setVisible(true);
  }
  function handleClose() {
    setVisible(false);
  }

  async function getStudents() {
    let data: any[] = [];
    const code = formik.values.phone;
    if (phoneRegex.test(code)) {
      await studentService
        .getByKey(`users.phone=${code.replace("+", "")}`)
        .then((response) => {
          data = studentService.getData(response);
        })
        .catch(() => (data = []));
    } else {
      await studentService
        .getByKey(`users.code=${code}`)
        .then((response) => {
          data = studentService.getData(response);
        })
        .catch(() => (data = []));
    }
    return data;
  }

  async function saveAsTeacher() {
    setLoading(true);
    let teacher = userHelper.getTeacher(user);
    if (teacher === null) {
      notification.error({
        message: "Oups",
        description: "L'utilisateur connecté n'est pas un enseignant",
      });
      if (typeof init === "function") {
        init();
      }
      setLoading(false);
      return;
    } else {
      teacher = getUid(teacher["@id"]);
    }
    const items = await getStudents();
    const isPhone = phoneRegex.test(formik.values.phone);
    if (items.length > 0) {
      const rep = await handleSaveSupport(items, toString(teacher), true);
      if (rep) {
        setVisible(false);
        //eslint-disable-next-line @typescript-eslint/no-empty-function
        formik.handleReset(() => {});
        setLoading(false);
      }
    } else {
      notification.warning({
        message: "Ajout de l'étudiant ou élève",
        description: `Nous n'avons pas trouvé un étudiant ou élève avec ce ${
          isPhone ? "numéro" : "code"
        }. Veuillez vérifier le ${isPhone ? "numéro" : "code"}.`,
      });
    }
    setLoading(false);
  }

  async function saveAsStudent() {
    const data = formik.values;
    setLoading(true);
    let student = userHelper.getStudent(user);
    if (student === null) {
      notification.error({
        message: "Oups",
        description: "L'utilisateur connecté n'est pas un étudiant",
      });
      setLoading(false);
      return;
    } else {
      student = getUid(student["@id"]);
    }
    await teacherService
      .getByKey(`users.phone=${data.phone.replace("+", "")}`)
      .then(async (response) => {
        const items = teacherService.getData(response);
        if (items.length > 0) {
          const rep = await handleSaveSupport(items, toString(student), false);
          if (rep) {
            setVisible(false);
            //eslint-disable-next-line @typescript-eslint/no-empty-function
            formik.handleReset(() => {});
            setLoading(false);
          }
        } else {
          notification.warning({
            message: "Ajout de l'étudiant",
            description:
              "Nous n'avons pas trouvé un étudiant avec ce numéro. Veuillez vérifier le numéro.",
          });
        }
      })
      .catch((reason) => {
        console.warn(reason.response);
        notification.warning({
          message: "",
          description: "Veuillez réessayer votre demande",
        });
      });
    setLoading(false);
  }

  async function handleCreate() {
    if (isResponsible) {
      saveAsTeacher();
    } else {
      saveAsStudent();
    }
  }
  async function handleSaveSupport(
    items: any[],
    responsible: string,
    isResponsible = true
  ) {
    let rep = false;
    if (items.length === 0) return rep;
    const item = items[0];
    /*if (item?.supports?.length > 0) {
      notification.error({
        message: "Relation entre responsable et étudiant",
        description: "Cette relation existe déjà",
      });
      return;
    }*/
    const id = getUid(item["@id"]);
    const data = {
      teacher: `/api/teachers/${responsible}`,
      student: `/api/students/${id}`,
      status: "in_pending",
    };
    if (!isResponsible) {
      data.teacher = `/api/teachers/${id}`;
      data.student = `/api/students/${responsible}`;
      data.status = "student_in_pending";
    }
    await managementService
      .store(data)
      .then(() => {
        if (isResponsible) {
          managementService
            .getByKey(`teacher=${responsible}`)
            .then((response) => {
              const data = supportService.getData(response);
              dispatch(setTeacherStudents(data));
            })
            .catch((reason) => {
              console.warn(reason);
            });
        } else {
          managementService
            .getByKey(`student=${responsible}`)
            .then((response) => {
              const data = supportService.getData(response);
              dispatch(setStudentTeachers(data));
            })
            .catch((reason) => {
              console.warn(reason);
            });
        }
        rep = true;
        notification.info({
          message: "Relation entre responsable et étudiant",
          description: "La rélation est établie avec succès",
        });
      })
      .catch((reason) => {
        console.warn(reason);
        notification.error({
          message: "Relation entre responsable et étudiant",
          description:
            "Nous n'avons pu établir la relation, veuillez réessayer.",
        });
      });
    return rep;
  }

  return (
    <React.Fragment>
      <Button
        title={
          isResponsible
            ? "Ajouter un élève ou étudiant"
            : "Ajouter un enseignant"
        }
        onClick={handleShow}
        type="primary"
        shape={type === "circle" ? "circle" : "default"}
      >
        {type === "default" &&
          `${
            isResponsible
              ? "Ajouter un étudiant ou élève"
              : "Ajouter un enseignant"
          }`}
        {type === "circle" && <UsergroupAddOutlined />}
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        visible={visible}
        centered
        onCancel={handleClose}
        okText="Ajouter"
        cancelText="Annuler"
        onOk={() => formik.handleSubmit()}
        confirmLoading={loading}
        title="Ajouter une relation"
      >
        <div>
          <DlInput
            type="tel"
            formik={formik}
            label={
              isResponsible
                ? "Le numéro ou code de l'étudiant ou élève"
                : "Le numéro de votre enseignant"
            }
            name="phone"
            placeholder={
              isResponsible ? "+243000000000 ou YEC-000-0000" : "+243000000000"
            }
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}

CreateStudent.propTypes = {
  type: propTypes.string,
  isResponsible: propTypes.bool,
  init: propTypes.any,
};
