import React from "react";
import PropTypes from "prop-types";

interface searchProps {
  setActive?: any;
  placeholder?: string;
}

export function SearchbarNav(props: searchProps) {
  const { placeholder, setActive } = props;

  function handleBlur(value: boolean) {
    if (typeof setActive === "function") {
      setActive(value);
    }
  }

  return (
    <React.Fragment>
      <div className="yc-search-box">
        <button className="yc-btn-search">
          <i className="material-icons">search</i>
        </button>
        <input
          type="search"
          className="yc-input-search"
          placeholder={placeholder}
          onFocus={() => handleBlur(false)}
          onBlur={() => handleBlur(true)}
        />
      </div>
    </React.Fragment>
  );
}

SearchbarNav.propTypes = {
  setActive: PropTypes.any,
};
