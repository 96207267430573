import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface forecatData {
  current: any;
  list: any[];
  all: any[];
  weeks: any[];
  itemCount: number;
  page: number;
}

const initialState: forecatData = {
  current: {},
  list: [],
  all: [],
  weeks: [],
  itemCount: 0,
  page: 1,
};

export const forecatSlice = createSlice({
  name: "forecats",
  initialState,
  reducers: {
    setForecat: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setForecats: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setCurrentWeekForecats: (state, action: PayloadAction<any[]>) => {
      state.weeks = action.payload;
    },
    setAllForecats: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setForecatsCount: (state, action: PayloadAction<number>) => {
      state.itemCount = action.payload;
    },
    setForecatCurrentPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});
export const {
  setForecat,
  setForecats,
  setAllForecats,
  setCurrentWeekForecats,
  setForecatsCount,
  setForecatCurrentPage,
} = forecatSlice.actions;
export default forecatSlice.reducer;
