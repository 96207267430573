import { ArrowRightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, List, Modal, Row } from "antd";
import { useFormik } from "formik";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import lessonService from "../../services/lessonService";
import subjectService from "../../services/subjectService";
import supportService from "../../services/supportService";
import { getUid } from "../../utils/helpers/title";
import userHelper from "../../utils/helpers/userHelper";
import { DlSelect } from "../input";

export function ModalSearch() {
  const [visible, setVisible] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [choices, setChoices] = React.useState<any[]>([]);
  const [placeholder, setPlaceholder] = React.useState("");
  const user = useSelector((state: RootState) => state.user.profil);
  const formik = useFormik({
    initialValues: { type: "subject" },
    onSubmit: handleReset,
  });

  React.useEffect(() => {
    if (userHelper.isStudent(user)) {
      setChoices([
        { value: "subject", title: "Matières" },
        { value: "lesson", title: "Leçons" },
      ]);
      formik.setValues({ type: "subject" });
    } else if (userHelper.isResponsible(user)) {
      setChoices([{ value: "children", title: "Enfants" }]);
      formik.setValues({ type: "children" });
    } else if (userHelper.isTeacher(user)) {
      setChoices([
        { value: "forecat", title: "Leçon de prévision" },
        { value: "student", title: "Elèves ou étudiants" },
      ]);
      formik.setValues({ type: "forecat" });
    }
  }, [user]);

  React.useEffect(() => {
    handleReset();
    switch (formik.values.type) {
      case "subject":
        setPlaceholder("Rechercher par le titre de la matière");
        break;
      case "lesson":
        setPlaceholder("Rechercher par le titre de la leçon");
        break;
      case "children":
        setPlaceholder("Rechercher par le prénom de l'enfant");
        break;
      case "forecat":
        setPlaceholder("Rechercher par le titre de la leçon");
        break;
      default:
        setPlaceholder("Rechercher");
        break;
    }
  }, [formik.values.type]);

  function handleReset() {
    setValue("");
    setLoading(false);
    setItems([]);
  }

  function handleShow() {
    setVisible(true);
  }
  function handleClose() {
    setVisible(false);
  }
  async function handleChange(v: string) {
    setValue(v);
    if (isEmpty(v)) {
      setItems([]);
    }
    switch (formik.values.type) {
      case "subject":
        setLoading(true);
        await subjectService
          .getByKey(`title=${v}&level=${user?.student?.level?.uid}`)
          .then((response) => {
            const data = subjectService.getData(response);
            setItems(data);
          })
          .catch((reason) => console.warn(reason));
        setLoading(false);
        break;

      case "lesson":
        setLoading(true);
        await lessonService
          .getByKey(`title=${v}&subject.level=${user?.student?.level?.uid}`)
          .then((response) => {
            const data = lessonService.getData(response);
            setItems(data);
          })
          .catch((reason) => console.warn(reason));
        setLoading(false);
        break;
      case "children":
        setLoading(true);
        await supportService
          .getByKey(
            `student.users.firstName=${v}&responsible=${
              userHelper.getResponsible(user)?.uid
            }`
          )
          .then((response) => {
            const data = supportService.getData(response);
            setItems(data);
          })
          .catch((reason) => console.warn(reason));
        setLoading(false);
        break;
      default:
        setItems([]);
        break;
    }
  }

  function getUrl(item: any, type: string) {
    let response = "#";
    switch (type) {
      case "subject":
        response = `/admin/subjects/view?v=${getUid(item["@id"])}`;
        break;
      case "lesson":
        response = `/admin/lessons/${getUid(item["@id"])}/view`;
        break;
      case "children":
        response = `/admin/child/view?v=${getUid(item.student["@id"])}`;
        break;
      default:
        response = "#";
        break;
    }
    return response;
  }

  function handleClick() {
    setVisible(false);
  }

  const renderItem = (item: any, index: number) => {
    return (
      <List.Item key={`search_${index}`}>
        <a href={getUrl(item, formik.values.type)} onClick={handleClick}>
          {formik.values.type !== "children" && (
            <React.Fragment>
              <ArrowRightOutlined /> {item?.title}
            </React.Fragment>
          )}
          {formik.values.type === "children" && (
            <React.Fragment>
              <ArrowRightOutlined />{" "}
              {`${upperFirst(item?.student?.users?.firstName)} ${upperCase(
                item?.student?.users?.lastName
              )}`}
            </React.Fragment>
          )}
        </a>
      </List.Item>
    );
  };
  return (
    <React.Fragment>
      <Button type="link" onClick={handleShow}>
        <SearchOutlined />
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        onCancel={handleClose}
        visible={visible}
        title="Recherche"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        footer={<div></div>}
        className="yc-modal-searchbar"
      >
        <Row
          gutter={4}
          align="middle"
          style={{ width: "100%", padding: "10px 24px" }}
        >
          <Col span={7}>
            <DlSelect formik={formik} list={choices} name="type" />
          </Col>
          <Col flex={1}>
            <Input.Search
              value={value}
              onChange={(e) => handleChange(e.currentTarget.value)}
              enterButton={<SearchOutlined />}
              loading={loading}
              placeholder={placeholder}
              itemScope
            />
          </Col>
        </Row>
        <Divider style={{ margin: "10px 0" }} />
        <div style={{ padding: "0 24px" }}>
          {isEmpty(value) && !loading && items.length === 0 && (
            <div
              style={{ textAlign: "center" }}
            >{`Recherche les informations par termes`}</div>
          )}
          {!isEmpty(value) && items.length === 0 && !loading && (
            <div
              style={{ textAlign: "center" }}
            >{`Recherche les informations par termes`}</div>
          )}
          {items.length > 0 && (
            <List dataSource={items} renderItem={renderItem} />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}
