import { Button, Divider, Modal, Typography } from "antd";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { paymentType } from "../utils/helpers/interface";
import { refKey } from "../utils/helpers/title";
import {
  merchantApi,
  merchantEmail,
  merchantID,
  merchantPassword,
} from "../utils/maxicash";

interface subscriptionInterface {
  user: any;
  payer: any;
  title: string;
  type: paymentType;
  setVisble: any;
}

export function CreatePayment(props: subscriptionInterface) {
  const { payer, title, user, type } = props;
  const [visible, setVisible] = React.useState(false);
  const [reference, setReference] = React.useState("");
  const total = 100;
  // const merchantID = "1f134f9556e54d1893adb6901f4a410c";
  // const merchantPassword = "8e397cc441ab4395b444aed771dae3be";

  if (isEmpty(user) || isEmpty(payer)) {
    return null;
  }

  function handleShow() {
    setVisible(true);
    if (typeof props.setVisble === "function") {
      props.setVisble(false);
    }
  }
  function handleClose() {
    setVisible(false);
  }

  React.useEffect(() => {
    const date = new Date();
    const time = date.getTime();
    setReference("REF-" + time);
  }, []);

  function getUrl(attributes: string) {
    const response = new URL(attributes, window.location.origin);
    return response;
  }

  function handleSubmit() {
    const element = {
      reference,
      submitDate: new Date().getTime(),
      back: window.location.href,
      user,
      payer,
      amount: total,
      currency: "$",
      merchant: "maxi-cash",
      type: type,
    };
    localStorage.setItem(refKey, JSON.stringify(element));
  }

  return (
    <React.Fragment>
      <Button onClick={handleShow} title={title} type="link">
        {title}
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        visible={visible}
        title="Paiement"
        centered
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={handleClose}
      >
        <form
          // action="https://api-testbed.maxicashapp.com/PayEntryPost"
          action={merchantApi}
          method="POST"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="PayType" value="MaxiCash" />
          <input type="hidden" name="Amount" value={total} />
          <input type="hidden" name="Currency" value="MaxiDollar" />
          <input type="hidden" name="Telephone" value={user?.phone} />
          <input type="hidden" name="Email" value={merchantEmail} />

          <input type="hidden" name="MerchantID" value={merchantID} />
          <input
            type="hidden"
            name="MerchantPassword"
            value={merchantPassword}
          />
          <input type="hidden" name="Language" value="Fr" />
          <input type="hidden" name="Reference" value={`${reference}`} />
          <input
            type="hidden"
            name="accepturl"
            value={`${getUrl(
              `/admin/payment?type=success&ben=${user["@id"]}&payer=${payer["@id"]}&format=${type}`,
            )}`}
          />
          <input
            type="hidden"
            name="cancelurl"
            value={`${getUrl(`/admin/payment?type=cancel`)}`}
          />
          <input
            type="hidden"
            name="declineurl"
            value={`${getUrl(`/admin/payment?type=failure`)}`}
          />
          <input
            type="hidden"
            name="notifyurl"
            value={`${getUrl(`/admin/payment?type=notify`)}`}
          />
          <div>
            <div>
              <Typography.Text
                strong
              >{`Nom de bénéficiaire : `}</Typography.Text>
              <p>
                {`${upperFirst(user?.firstName)} ${upperCase(
                  user?.lastName,
                )} (${user?.phone}) `}
              </p>
            </div>
            <div>
              <Typography.Text strong>{`Nom de payeur : `}</Typography.Text>
              <p>
                {`${upperFirst(payer?.firstName)} ${upperCase(
                  payer?.lastName,
                )} (${payer?.phone})`}
              </p>
            </div>
            <div>
              <Typography.Text strong>{`Montant à payer : `}</Typography.Text>
              <span>{`${total / 100} $`}</span>
            </div>
          </div>
          <Divider />
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button htmlType="submit" type="primary">
              {`Lancer le paiement`}
            </Button>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  );
}
