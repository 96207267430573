import { List } from "antd";
import React from "react";
import { ProductItem } from "../card/product";

export interface productInterface {
  title: string;
  content: string;
  image?: string;
  url?: string;
}

export function ProductComponent() {
  const [items, setItems] = React.useState<productInterface[]>([]);
  const renderItem = (item: productInterface, index: number) => {
    return (
      <List.Item>
        <ProductItem
          content={item.content}
          title={item.title}
          image={item.image}
          key={`${index}_product`}
        />
      </List.Item>
    );
  };

  React.useEffect(() => {
    const list: productInterface[] = [];
    list.push({
      title: "Yeccothèque",
      content: `Consultez les livres d'enseignement pour développer
      vos capacités et mieux préparer vos leçons connectezvous à plus d'un million d'ouvrages grâce à
      Yeccothèque la plus grande bibliothèque numérique
      pour l'Éducation nationale en République
      démocratique du Congo.`,
      image: require("../../assets/images/tablet-g3cd415618_1920.jpg"),
    });
    list.push({
      title: "Yeccolapp Awards",
      content: `Participez aux compétitions scolaires et gagnez
      des prix (tablettes, ordinateurs, frais de
      scolarité et trousses de retour à l'école)`,
      image: require("../../assets/images/yec-medailles.png"),
    });
    setItems(list);
  }, []);

  return <List dataSource={items} renderItem={renderItem} bordered={false} />;
}
