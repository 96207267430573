import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface lessonInterface {
  "@context": string;
  "@id": string;
  "@type": string;
  title: string;
  content: string;
  files?: string[];
  readings?: string[];
  subject: any;
  createdAt: string;
  updatedAt: string;
  state: string;
  foregroundTo?: string;
}
export interface lessonData {
  profil: lessonInterface;
  current: lessonInterface;
  list: lessonInterface[];
  all: lessonInterface[];
}

const initialState: lessonData = {
  profil: {
    "@context": "",
    "@id": "",
    "@type": "",
    content: "",
    createdAt: "",
    updatedAt: "",
    state: "",
    subject: "",
    title: "",
  },
  current: {
    "@context": "",
    "@id": "",
    "@type": "",
    content: "",
    createdAt: "",
    updatedAt: "",
    state: "",
    subject: "",
    title: "",
  },
  list: [],
  all: [],
};

export const lessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    setProfil: (state, action: PayloadAction<lessonInterface>) => {
      state.profil = action.payload;
    },
    setLesson: (state, action: PayloadAction<lessonInterface>) => {
      state.current = action.payload;
    },
    setLessons: (state, action: PayloadAction<lessonInterface[]>) => {
      state.list = action.payload;
    },
    setDashboardLessons: (state, action: PayloadAction<lessonInterface[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setProfil, setLesson, setLessons, setDashboardLessons } =
  lessonSlice.actions;
export default lessonSlice.reducer;
