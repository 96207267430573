import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface userData {
  profil: any;
  current: any;
  list: any[];
  toBlock: boolean;
  subscriptions: any[];
  payments: any[];
  otherPayments: any[];
  subscription: any;
}

const initialState: userData = {
  profil: {},
  current: {},
  list: [],
  toBlock: false,
  subscriptions: [],
  otherPayments: [],
  payments: [],
  subscription: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProfil: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setBlockUser: (state, action: PayloadAction<boolean>) => {
      state.toBlock = action.payload;
    },
    setUserAllPayments: (state, action: PayloadAction<any[]>) => {
      state.payments = action.payload;
    },
    setUserAllSubscriptions: (state, action: PayloadAction<any[]>) => {
      state.subscriptions = action.payload;
    },
    setUserOtherPayments: (state, action: PayloadAction<any[]>) => {
      state.otherPayments = action.payload;
    },
    setUserSubscription: (state, action: PayloadAction<any>) => {
      state.subscription = action.payload;
    },
  },
});
export const {
  setProfil,
  setCurrent,
  setList,
  setBlockUser,
  setUserAllPayments,
  setUserAllSubscriptions,
  setUserOtherPayments,
  setUserSubscription,
} = userSlice.actions;
export default userSlice.reducer;
