import { Layout, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useSelector } from "react-redux";
import { DescriptionCard } from "../../components/card/description";
import { RootState } from "../../redux/store";
import dateHelpers from "../../utils/helpers/dateHelpers";
import userHelper from "../../utils/helpers/userHelper";

const colums: ColumnsType<object> = [
  {
    title: "Type d'abonnement",
    dataIndex: "title",
  },
  {
    title: "Montant",
    dataIndex: "amount",
  },
  {
    title: "Date de fin d'abonnement",
    dataIndex: "date",
  },
];
export { colums as SubscriptionColum };

export function SubscriptionPofil() {
  const subscriptions = useSelector(
    (state: RootState) => state.subscription.all,
  );
  const subscription = useSelector(
    (state: RootState) => state.user.subscription,
  );
  const [items, setItems] = React.useState<any>([]);

  React.useEffect(() => {
    const list: any = [];
    [...subscriptions]
      .sort((a, b) => b?.validatedAt?.localeCompare(a?.validatedAt))
      .forEach((p) => {
        if (p.status === "in_progress") {
          list.push({
            date: p.validatedAt
              ? dateHelpers.getDate(p.validatedAt, "dddd DD MMMM YYYY")
              : "",
            amount: `${p.amount} ${p.currency ?? "$"}`,
            title: `Abonnement`,
          });
        } else {
          list.push({
            date: "-----",
            amount: `${p.amount} ${p.currency ?? "$"}`,
            title: `Abonnement`,
          });
        }
      });
    setItems(list);
  }, [subscriptions]);

  return (
    <React.Fragment>
      <Layout className="yc-box" style={{ minHeight: "100vh" }}>
        <DescriptionCard
          title="Niveau d'activité actuelle"
          description={userHelper.getSubscriptionValidation(subscription)}
          heading={2}
        />
        <Typography.Title level={2}>{"Tous les abonnements"}</Typography.Title>
        <Table className="yc-table" dataSource={items} columns={colums} />
      </Layout>
    </React.Fragment>
  );
}
