import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface levelInterface {
  "@context"?: string;
  "@id"?: string;
  uid: string;
  "@type"?: string;
  title: string;
  promotion: number;
  subjects?: string[];
  registers?: string[];
  groups: any[];
}

export interface levelData {
  profil: levelInterface;
  current: levelInterface;
  list: levelInterface[];
}

const initialState: levelData = {
  profil: {
    groups: [],
    promotion: 0,
    registers: [],
    title: "",
    "@context": "",
    "@id": "",
    "@type": "",
    subjects: [],
    uid: "",
  },
  current: {
    groups: [],
    uid: "",
    promotion: 0,
    registers: [],
    title: "",
    "@context": "",
    "@id": "",
    "@type": "",
    subjects: [],
  },
  list: [],
};

export const levelSlice = createSlice({
  name: "level",
  initialState,
  reducers: {
    setProfilLevel: (state, action: PayloadAction<levelInterface>) => {
      state.profil = action.payload;
    },
    setCurrentLevel: (state, action: PayloadAction<levelInterface>) => {
      state.current = action.payload;
    },
    setLevels: (state, action: PayloadAction<levelInterface[]>) => {
      state.list = action.payload;
    },
  },
});
export const { setProfilLevel, setCurrentLevel, setLevels } =
  levelSlice.actions;
export default levelSlice.reducer;
