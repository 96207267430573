import { Card, Col, Image, Row, Typography } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

interface descriptionCardProps {
  logo?: React.ReactElement;
  title: string;
  description?: string;
  avatar?: string;
  image?: string;
  heading: 1 | 5 | 2 | 3 | 4 | undefined;
}
export function DescriptionCard(props: descriptionCardProps) {
  return (
    <React.Fragment>
      <Card className="yc-card" style={{ width: "100%", margin: 10 }}>
        <Row gutter={8}>
          <Col span={4}>
            {!isEmpty(props.image) && isEmpty(props.logo) && (
              <Image src={props.image} preview={false} />
            )}
            {!isEmpty(props.logo) && isEmpty(props.image) && props.logo}
          </Col>
          <Col flex={1}>
            <Typography.Title level={props.heading}>
              {props.title}
            </Typography.Title>
            <Typography.Text>{props.description}</Typography.Text>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
}

DescriptionCard.propTypes = {
  props: PropTypes.any,
};
