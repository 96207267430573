import { AppstoreFilled } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React from "react";
import { appName } from "../../utils/helpers/title";
import { ProductComponent } from "./products";

export function ToolNavbar() {
  const [visible, setVisible] = React.useState(false);
  return (
    <React.Fragment>
      <Button
        title="Autres produits"
        type="text"
        onClick={() => setVisible(true)}
      >
        <AppstoreFilled style={{ fontSize: 18 }} />
      </Button>
      <Drawer
        visible={visible}
        title={`Tous les produits ${appName}`}
        onClose={() => setVisible(false)}
      >
        <ProductComponent />
      </Drawer>
    </React.Fragment>
  );
}
