import { toUpper } from "lodash";
import { appName } from "./utils/helpers/title";

interface ruleInterface {
  position: number;
  title: string;
  content: string;
}

const rules: ruleInterface[] = [
  {
    title: toUpper("Mentions légales"),
    position: 1,
    content: `Si l’édition du site est assurée par une personne morale :
    L'édition du site ${appName} est assurée par la Société (indiquer le nom de la société et la forme
    sociale) au capital de (indiquer le montant), immatriculée au RCS de Kinshasa sous le numéro
    (indiquer le SIREN) dont le siège social est situé au (indiquer l’adresse), (indiquer le numéro de téléphone),
    (indiquer l’adresse email).
    N° de TVA intracommunautaire : (indiquer le numéro)
    Le Directeur de la publication est (indiquer le nom).
    Si l’édition du site est assurée par une personne physique :
    L’édition et la direction de la publication du site ${appName} est assurée par (Madame ou
    Monsieur, indiquer l’identité complète), domiciliée (indiquer l’adresse), (numéro de téléphone), (adresse
    email), (éventuellement si la personne physique y est assujettie indiquer le numéro d'inscription au registre
    du commerce et des sociétés ou au répertoire des métiers)
    Eventuellement : N° de TVA intracommunautaire : (indiquer le numéro)
    L'hébergeur du site ${appName} est la Société DILL,
    au capital de (indiquer le montant), immatriculée au RCS de Kinshasa sous le numéro (indiquer le
    SIREN), dont le siège social est situé au (indiquer l’adresse), (indiquer le numéro de téléphone), (indiquer
    l’adresse email). `,
  },
  {
    position: 2,
    title: "Accès au site",
    content: `Le site ${appName} permet à l'Utilisateur un accès gratuit aux services suivants :
    - Lecture des cours
    - Sauvea
    Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais
    supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.)
    sont à sa charge.
    Si le site comprend un espace membre :
    L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le
    formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des
    informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email.
    L’Utilisateur est responsable de la mise à jour des informations fournies. Il lui est précisé qu’il peut les
    modifier en se connectant à son espace membre.
    Pour accéder aux services, l’Utilisateur devra s'identifier à l'aide de son nom d’utilisateur et de son mot de
    passe qui lui seront communiqués après son inscription et qui sont strictement personnels. A ce titre, il s’en
    interdit toute divulgation. Dans le cas contraire, il restera seul responsable de l’usage qui en sera fait.
    L’Utilisateur pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace
    personnel ou envoyant un email à : (préciser l’adresse). Celle-ci sera effective dans un délai raisonnable.
    En cas de non respect des conditions générales de vente et/ou d’utilisation, le site ${appName}
    aura la possibilité de suspendre voire de fermer le compte d’un Utilisateur après mise en demeure adressée
    par voie électronique et restée sans effet.
    Toute suppression de compte, quel qu’en soit le motif, engendre la suppression pure et simple de toutes
    informations personnelles de l’Utilisateur.
    Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou
    serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la
    responsabilité de ${appName}. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à
    l’éditeur de toute interruption ou suspension de service, même sans préavis.
    L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur
    communiqué à l’ARTICLE 1. `,
  },
];
export default rules;
