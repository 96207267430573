import { StarOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Row, Typography } from "antd";
import React from "react";
import { isEmpty } from "lodash";

interface userAvatarInterface {
  photo?: string;
  title: string;
  subtitle?: string;
  buttons?: any[];
  note?: string;
}

export function UserAvatar(props: userAvatarInterface) {
  const { photo, subtitle, title, buttons, note } = props;

  const actions = isEmpty(buttons) ? [] : (buttons as any[]);

  return (
    <div
      style={{ textAlign: "center", cursor: "pointer", padding: "10px 0" }}
      className="yc-box"
    >
      <Avatar className="yc-box-shadow" size={150} src={photo}>
        <UserOutlined
          size={100}
          style={{ color: "#000000", fontSize: 50, textAlign: "center" }}
        />
      </Avatar>
      <Typography.Title
        level={2}
        style={{ fontSize: 16, padding: 5, margin: "5px -10px 5px" }}
      >
        {title}
      </Typography.Title>
      <Typography.Text>{subtitle}</Typography.Text>
      {!isEmpty(note) && (
        <Row
          style={{ width: "100%", padding: "0 10px" }}
          align="middle"
          gutter={4}
        >
          <Col>
            <StarOutlined style={{ color: "gold" }} />
          </Col>
          <Col flex={1}>
            <Typography.Paragraph
              ellipsis={{ rows: 3, expandable: true }}
              style={{ textAlign: "justify", padding: "0 10px" }}
            >
              {note}
            </Typography.Paragraph>
          </Col>
        </Row>
      )}
      {actions.length > 0 && (
        <Row gutter={4} justify="center">
          {actions.map((p: any, index: number) => (
            <Col key={`btn_${index}`}>{p}</Col>
          ))}
        </Row>
      )}
    </div>
  );
}
