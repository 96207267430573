import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface teacherData {
  profil: any;
  current: any;
  levels: any[];
  subjects: any[];
  students: any[];
}

const initialState: teacherData = {
  profil: {},
  current: {},
  levels: [],
  subjects: [],
  students: [],
};

export const teacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {
    setTeacher: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrentTeacher: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setTeacherLevels: (state, action: PayloadAction<any[]>) => {
      state.levels = action.payload;
    },
    setTeacherSubjects: (state, action: PayloadAction<any[]>) => {
      state.subjects = action.payload;
    },
    setTeacherStudents: (state, action: PayloadAction<any[]>) => {
      state.students = action.payload;
    },
  },
});
export const {
  setTeacher,
  setCurrentTeacher,
  setTeacherLevels,
  setTeacherSubjects,
  setTeacherStudents,
} = teacherSlice.actions;
export default teacherSlice.reducer;
