import { Button, Drawer } from "antd";
import React from "react";
import PropTypes from "prop-types";
import LeftMenu from "./leftMenu";
import logo from "../../assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { SidebarMenu } from "./sidebarMenu";
import { appName } from "../../utils/helpers/title";
import { ToolNavbar } from "./tool";
import { FooterHome } from "../../screens/root/footer";

function Navbar(props) {
  const [visible, setVisible] = React.useState(false);
  const location = useLocation();
  function handleClose() {
    setVisible(false);
  }
  function handleOpen() {
    setVisible(true);
  }

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("admin-navbar").classList.add("yc-navbar-shadow");
    } else {
      document
        .getElementById("admin-navbar")
        .classList.remove("yc-navbar-shadow");
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };

  return (
    <main>
      <nav className="yc-menu yc-menu-fixed" id="admin-navbar">
        <div className="yc-logo">
          <a href="/">
            <img src={logo} preview={false} sizes="20px" />
          </a>
        </div>
        <div className="yc-menu-con">
          <div className="yc-left-menu">
            <LeftMenu mode="horinzotal" current={location.pathname} />
          </div>
          <div className="yc-right-menu"></div>
          <div
            className="yc-navigator"
            style={{ float: "none", height: "auto", padding: 0 }}
          >
            <ToolNavbar />
          </div>
          <Button className="yc-navigator" onClick={handleOpen}>
            <span className="yc-navigator-btn"></span>
          </Button>
          <Drawer
            title={appName}
            placement="right"
            closable={true}
            onClose={handleClose}
            visible={visible}
            bodyStyle={{ display: "flex", flexDirection: "column" }}
            contentWrapperStyle={{ maxWidth: 330 }}
          >
            <SidebarMenu setVisible={setVisible} current={location.pathname} />
          </Drawer>
        </div>
      </nav>
      <div style={{ overflowX: "hidden", minHeight: "80vh" }}>
        {props.children}
      </div>
      <FooterHome />
    </main>
  );
}
Navbar.propTypes = {
  children: PropTypes.element,
};

export { Navbar };
