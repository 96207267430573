import { isEmpty } from "lodash";

export interface groups {
  "@id"?: string;
  "@type"?: string;
  name: string;
  type: string;
  parent?: string;
  children?: groups[];
}

class LevelHelper {
  getClass(title: string, groups: groups): string {
    let response: string;
    response = title;
    if (!isEmpty(groups)) {
      if (groups.type === "cycle") {
        response += " " + groups.name;
      }
    }
    return response;
  }
}

export default new LevelHelper();
