import { configureStore } from "@reduxjs/toolkit";
import lessonReducer from "./lessonReducer";
import levelReducer from "./levelReducer";
import subjectReducer from "./subjectReducer";
import userReducer from "./userReducer";
import readingReducer from "./readingReducer";
import chapiterReducer from "./chapiterReducer";
import fileReducer from "./fileReducer";
import studentReducer from "./studentReducer";
import supportReducer from "./supportReducer";
import subscriptionReducer from "./subscriptionReducer";
import teacherReducer from "./teacherReducer";
import forecatReducer from "./forecatReducer";
import managementReducer from "./managementReducer";
const store = configureStore({
  reducer: {
    user: userReducer,
    subject: subjectReducer,
    lesson: lessonReducer,
    level: levelReducer,
    reading: readingReducer,
    chapiter: chapiterReducer,
    file: fileReducer,
    student: studentReducer,
    support: supportReducer,
    subscription: subscriptionReducer,
    teacher: teacherReducer,
    forecat: forecatReducer,
    management: managementReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
