import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ManagementData {
  currentStudent: any;
  currentTeacher: any;
  list: any[];
}

const initialState: ManagementData = {
  currentStudent: {},
  currentTeacher: {},
  list: [],
};

export const ManagementSlice = createSlice({
  name: "management",
  initialState,
  reducers: {
    setCurrentStudent: (state, action: PayloadAction<any>) => {
      state.currentStudent = action.payload;
    },
    setCurrentTeacher: (state, action: PayloadAction<any>) => {
      state.currentTeacher = action.payload;
    },
    setManagementList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
  },
});
export const { setCurrentStudent, setCurrentTeacher, setManagementList } =
  ManagementSlice.actions;
export default ManagementSlice.reducer;
