import { Button, Image, Layout, notification, Row, Typography } from "antd";
import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.jpg";
import { DlInput } from "../components/input";
import { appName, setAppTitle } from "../utils/helpers/title";
import * as yup from "yup";
import userService from "../services/userService";
import { useDispatch } from "react-redux";
import userHelper from "../utils/helpers/userHelper";
import { toUpper } from "lodash";

const { Text } = Typography;
export const phoneRegex = /^[+][(]{0,1}[0-9]{1,4}[)]{0,1}([-\s\./0-9]{6,10})$/; //eslint-disable-line no-useless-escape

export function LoginScreen() {
  const regex =
    /(^[+][(]{0,1}[0-9]{1,4}[)]{0,1}([-\s\./0-9]{6,10})|^YEC-[0-9]{1,4}-[0-9]{1,4})$/; //eslint-disable-line no-useless-escape
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { code: "" },
    onSubmit: handleConnexion,
    validationSchema: yup.object({
      code: yup
        .string()
        .required("Le champ est requis")
        .matches(regex, { message: "Ecrivez correctement votre numéro" }),
    }),
  });

  async function loginWithCode(code: string, withPhone: boolean) {
    await userService
      .login({ username: code, password: code })
      .then(async (response) => {
        const data = response.data;
        const token = data.token;
        const uid = data.user;
        const user = await getUser(uid);
        if (user == null) {
          notification.error({
            message: "Connexion",
            description: "Echec de connexion",
          });
          return;
        }
        userHelper.setToken(token);
        userHelper.setData(user, dispatch);
        location.href = "/admin";
      })
      .catch(() => {
        notification.error({
          message: "Connexion",
          description: `La connexion a échoué: veuillez vérifier votre ${
            withPhone ? "numéro de téléphone" : "code " + appName
          }`,
        });
      });
  }

  async function handleConnexion() {
    setLoading(true);
    const value = formik.values;
    if (phoneRegex.test(value.code)) {
      //treat a phone
      await userService
        .getByKey(`phone=${value.code.replace("+", "")}`)
        .then(async (response) => {
          const data = userService.getData(response);
          if (data.length > 0) {
            const item = data[0];
            await loginWithCode(item.code, true);
          } else {
            notification.error({
              message: "Connexion",
              description: `La connexion a échoué: veuillez vérifier votre numéro de téléphone`,
            });
          }
        })
        .catch((reason) => {
          console.warn(reason);
        });
    } else {
      await loginWithCode(value.code, false);
    }
    setLoading(false);
  }

  async function getUser(uid: string) {
    let response: any = null;
    await userService
      .get(uid)
      .then((rep) => {
        response = rep.data;
      })
      .catch((reason) => {
        console.warn(reason);
      });
    return response;
  }

  setAppTitle("Connexion");

  return (
    <Row
      align="middle"
      justify="center"
      className="yc-100vh"
      style={{ background: "#fff" }}
    >
      <Layout style={{ background: "#fff" }}>
        <Row
          className="yc-connect"
          style={{ flexDirection: "column" }}
          justify="center"
        >
          <div style={{ textAlign: "center", margin: "20px" }}>
            <Image src={logo} style={{ width: 100 }} preview={false} />
          </div>
          <Typography.Title
            level={4}
            style={{ margin: 0, textAlign: "center" }}
          >
            Connexion
          </Typography.Title>
          <Text style={{ textAlign: "center", margin: 20 }}>
            {`Entrez votre code ${appName} ou votre numéro de téléphone.`}
          </Text>
          <div style={{ margin: "0 20px" }}>
            <DlInput
              formik={formik}
              label={`Code ${toUpper(appName)} ou Numéro de tél.`}
              name="code"
              placeholder="+243000000000 ou YEC-000-000"
              type="text"
            />
            {/*<Link to="/home/forgonten">Code oublié ?</Link>*/}
          </div>
          <div style={{ textAlign: "center", margin: "10px 0" }}>
            <Button
              loading={loading}
              disabled={loading}
              shape="round"
              type="primary"
              onClick={() => formik.handleSubmit()}
            >
              Connexion
            </Button>
          </div>
          {/* <Text style={{ textAlign: "center" }}>
            {`Vous n'avez pas de compte ${toUpper(appName)} ?`}
          </Text>
          <Link to="/home/register" style={{ textAlign: "center" }}>
            Créer un compte
          </Link> */}
          <div style={{ margin: "40px 0 0 0", textAlign: "center" }}>
            <Link to="/">
              <Text>{`Vous n'avez pas à vous connecter?`}</Text>
            </Link>
          </div>
        </Row>
      </Layout>
    </Row>
  );
}
