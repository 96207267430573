import { setDocuments } from "../../redux/fileReducer";
import fileService from "../../services/fileService";
import { getUid } from "./title";
import userHelper from "./userHelper";

class StudentHelper {
  async getDocuments(user: any, dispatch?: any) {
    let items: any[] = [];
    let level = "";
    if (!userHelper.isStudent(user)) {
      return items;
    }
    const student = userHelper.getStudent(user);
    level = getUid(student.level["@id"]);
    const query = `typeMime=application/pdf&lesson.subject.level=${level}&limit=1000`;
    await fileService
      .getByKey(query)
      .then((response) => {
        items = fileService.getData(response);
      })
      .catch(() => {
        items = [];
      });
    if (typeof dispatch === "function") {
      dispatch(setDocuments(items));
    }
    return items;
  }
}

export default new StudentHelper();
