import { Layout } from "antd";
import React from "react";
import { Chart } from "react-google-charts";
import ChildStat from "../../utils/helpers/childStat";
import dateHelpers from "../../utils/helpers/dateHelpers";

interface graphicInterface {
  items: any[];
}

export function GraphicResponsible(props: graphicInterface) {
  const [data, setData] = React.useState<any[]>([]);

  React.useEffect(() => {
    init();
  }, [props.items]);

  async function init() {
    const weeks = dateHelpers.getWeekDays(new Date());
    const stat = new ChildStat(props.items, weeks);
    const list = await stat.getStats();
    console.log("LIST", list);
    setData(list);
  }

  return (
    <Layout style={{ padding: "10px 20px" }}>
      <Chart
        chartType="LineChart"
        width="100%"
        height="500px"
        data={data}
        style={{ padding: 10 }}
        options={{
          chart: {
            title: "Evolution des enfants par rapport aux leçons suivis",
            subtitle: "",
            is3D: true,
          },
          legend: "ddds",
          colors: ["red", "gold", "silver", "blue", "green", "orange"],
          hAxis: { title: "Les jours de la semaine" },
          yAxis: { title: "Le nombre de leçons suivies" },
        }}
      />
    </Layout>
  );
}
