import { Image, Layout, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.jpg";
import { setAppTitle } from "../utils/helpers/title";

export function RegisterBlockedScreen() {
  setAppTitle("Inscription bloqué");

  return (
    <div>
      <Row
        align="middle"
        justify="center"
        className="yc-100vh"
        style={{ background: "#fff" }}
      >
        <Layout style={{ background: "#fff" }}>
          <Row
            className="yc-connect"
            style={{ flexDirection: "column" }}
            justify="center"
          >
            <div style={{ textAlign: "center", margin: "20px" }}>
              <Image src={logo} style={{ width: 100 }} />
            </div>
            <Typography.Title
              level={4}
              style={{ margin: 0, textAlign: "center" }}
            >
              {`L'inscription est reservée`}
            </Typography.Title>

            <div
              style={{
                margin: "40px 0 0 0",
                textAlign: "center",
                borderTop: "solid 1px",
                padding: "10px",
              }}
            >
              <Link to="/home/login">{`Se connecter avec un compte existant?`}</Link>
            </div>
          </Row>
        </Layout>
      </Row>
    </div>
  );
}
