import { Button, Modal, notification } from "antd";
import React from "react";
import { DlTextarea } from "../../components/input";
import { useFormik } from "formik";
import * as yup from "yup";
import managementService from "../../services/managementService";
import { getUid } from "../../utils/helpers/title";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import searchHelper from "../../utils/helpers/searchHelper";

interface createNoteInteface {
  management: any;
}

export function CreateNote(props: createNoteInteface) {
  const { management } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.profil);
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const formik = useFormik({
    initialValues: { note: management.note },
    onSubmit: handleSave,
    validationSchema: yup.object({
      note: yup.string().required("Note est requise pour la validation"),
    }),
  });

  React.useEffect(() => {
    formik.setValues({ note: management.note });
    console.log("MANAGE", management);
  }, [management]);

  async function handleSave() {
    const data = formik.values;
    const uid = getUid(management["@id"]);
    console.log("DATA", uid, data);
    setLoading(true);
    await managementService
      .update(uid, data)
      .then(async (response) => {
        notification.info({
          message: "Mise à jour de note",
          description: "La note d'appréciation a été mise à jour avec succès",
        });
        const rep = response.data;
        await searchHelper.getTeacherManagement(user, dispatch);
        setVisible(false);
        formik.resetForm();
        formik.setValues({ note: rep.note });
      })
      .catch(() => {
        notification.error({
          message: "Mise à jour de note",
          description: "La note d'appréciation n'a pas été envoyé",
        });
      });
    setLoading(false);
  }
  async function handleHide() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  function handleShow() {
    setVisible(true);
  }

  return (
    <React.Fragment>
      <Button type="primary" onClick={handleShow}>
        {`Ajouter une note`}
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        visible={visible}
        confirmLoading={loading}
        okText="Valider"
        cancelText="Annuler"
        onOk={() => formik.handleSubmit()}
        onCancel={handleHide}
        centered
      >
        <div>
          <DlTextarea
            formik={formik}
            name="note"
            label="Note de l'élève"
            placeholder="Dites votre appréciation de l'élève"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
