import { isEmpty } from "lodash";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Navbar } from "../components/navigation/navbar";
import { routeInterface, routes } from "../routes";
import levelService from "../services/levelService";
import registerService from "../services/registerService";
import userService from "../services/userService";
import { getCurrentRoute, getUid, setAppTitle } from "../utils/helpers/title";
import userHelper from "../utils/helpers/userHelper";
import { setProfilLevel } from "../redux/levelReducer";
import { SplashScreen } from "./splash";
import { FallbackScreen } from "./fallback";
import { BlockScreen } from "./block";
import { NotFoundScreen } from "./404";
import { RootState } from "../redux/store";
import dateHelpers from "../utils/helpers/dateHelpers";
import ReactGA from "react-ga4";

export function AdminLayout() {
  const userAdmin = userHelper.getData();
  const user = useSelector((state: RootState) => state.user.profil);
  const level = useSelector((state: RootState) => state.level.profil);
  const subscription = useSelector(
    (state: RootState) => state.user.subscription,
  );
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState<routeInterface[]>(routes);
  const dispatch = useDispatch();
  const routerLocation = useLocation();
  // const [displayLocation, setDisplayLocation] = React.useState(routerLocation);
  // const [transitionStage, setTransistionStage] = React.useState("fadeIn");

  // function onAnimation() {
  //   if (transitionStage === "fadeOut") {
  //     setTransistionStage("fadeIn");
  //     setDisplayLocation(routerLocation);
  //   }
  // }

  // function scrollToTop() {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const currentRoute = getCurrentRoute(routerLocation.pathname);
    if (!isEmpty(currentRoute)) {
      ReactGA.send({
        hitType: "pageview",
        page: `${routerLocation.pathname}`,
        title: currentRoute?.title ?? `Yeccolapp`,
      });
      setAppTitle(
        currentRoute?.label ?? "Yeccolapp",
        currentRoute?.description,
      );
    }
  }, [routerLocation]);

  // React.useEffect(() => {
  //   if (routerLocation !== displayLocation) setTransistionStage("fadeOut");
  //   const index = routes.findIndex(
  //     (p) =>
  //       `${p.layout}${p.path}` === routerLocation.pathname ||
  //       `${p.layout}${p.path}/` === routerLocation.pathname
  //   );
  //   if (index != -1) {
  //     setAppTitle(routes[index]?.label, routes[index]?.description);
  //   }
  //   // dispatch(setFloatUrl(""));
  //   scrollToTop();
  // }, [routerLocation, displayLocation]);

  React.useEffect(() => {
    const { now, validatedAt } = subscription;
    const count = dateHelpers.getDifference(validatedAt, now, "days");
    console.log("Count", count, validatedAt);
    const elements = routes.filter(
      (p) =>
        p.layout === "/admin" &&
        userHelper.isVisible(p.visibility, user, p.subscribeable, count >= 0),
    );
    setItems(elements);
  }, [user, subscription]);
  if (isEmpty(userAdmin)) {
    return (
      <Navigate
        to="/home/login"
        replace
        state={{ message: "Vous devez vous connecter avant tout" }}
      />
    );
  }
  async function init() {
    if (isEmpty(user.uid)) {
      await userService
        .get(userAdmin.uid)
        .then((response) => {
          const data = response.data;
          userHelper.setData(data, dispatch);
        })
        .catch((reason) => {
          console.log(reason.response);
        });
    }
  }
  async function searchStudent() {
    if (isEmpty(level.uid)) {
      if (!isEmpty(user.student)) {
        if (!isEmpty(user.student.uid)) {
          await registerService
            .getByKey(`student=${user.student.uid}`)
            .then(async (response) => {
              const data = registerService.getData(response);
              if (data.length > 0) {
                const item = data[0];
                await levelService
                  .get(getUid(item.level))
                  .then((rep) => {
                    dispatch(setProfilLevel(rep.data));
                  })
                  .catch((reason) => console.log("ds", reason));
              }
            })
            .catch(() => {
              console.log("dsd");
            });
        }
      }
    }
  }
  React.useEffect(() => {
    onInit();
  }, []);

  const onInit = React.useCallback(() => {
    init();
    setAppTitle("Accueil");
    Notification.requestPermission().then((result) => {
      console.log("NOTIFS", result);
    });
  }, []);

  async function login() {
    const data = {
      username: user.code,
      password: user.code,
    };
    await userService
      .login(data)
      .then((response) => {
        const token = response.data.token;
        userHelper.setToken(token);
      })
      .catch((reason) => {
        console.warn(reason.response);
      });
  }

  React.useEffect(() => {
    searchStudent();
    login();
  }, [user]);

  return (
    <React.Fragment>
      <SplashScreen
        loading={loading || isEmpty(user.uid)}
        setLoading={setLoading}
      />
      <BlockScreen />
      <Navbar key="nav-admin">
        <Suspense fallback={<FallbackScreen />}>
          {/* <div onAnimationEnd={onAnimation} className={`${transitionStage}`}> */}
          <Routes>
            {items.map((p, index) => {
              return (
                <Route
                  path={`${p.path}`}
                  element={<p.component />}
                  key={`${p.name}${index}`}
                />
              );
            })}
            <Route path={`*`} element={<NotFoundScreen />} key={`notfound`} />
          </Routes>
          {/* </div> */}
        </Suspense>
      </Navbar>
    </React.Fragment>
  );
}
