import {
  AuditOutlined,
  DollarOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Divider, Modal, Popover } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import userHelper from "../../utils/helpers/userHelper";
import propTypes from "prop-types";

export const ProfilContent = ({ setVisible }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profil);
  function handleProfilScreen() {
    navigate("/admin/profil");
  }
  function onLoagout() {
    userHelper.setLogout();
    location.href = "/";
  }
  function handleLogout() {
    Modal.confirm({
      title: "Déconnexion",
      content: "Voulez-vous vous déconnecter?",
      cancelText: "Annuler",
      okText: "Se déconnecter",
      onOk: onLoagout,
    });
    if (typeof setVisible === "function") {
      setVisible(false);
    }
  }
  return (
    <div>
      <div>
        <Button type="text" onClick={handleProfilScreen}>
          <UserOutlined /> Votre profil
        </Button>
      </div>
      <div>
        <Button type="text">
          <Link to="/admin/payments">
            <DollarOutlined style={{ paddingRight: 5 }} />
            {`Mes paiements`}
          </Link>
        </Button>
      </div>
      {userHelper.isStudent(user) && (
        <div>
          <Button type="text">
            <Link to="/admin/responsibles">
              <TeamOutlined style={{ paddingRight: 5 }} />
              {`Les responsables`}
            </Link>
          </Button>
        </div>
      )}
      {userHelper.isStudent(user) && (
        <div>
          <Button type="text">
            <Link to={"/admin/teachers"}>
              <AuditOutlined style={{ paddingRight: 5 }} />
              {`Les enseignants`}
            </Link>
          </Button>
        </div>
      )}
      <Divider style={{ margin: 0 }} />
      <div>
        <Button onClick={handleLogout} type="text" danger>
          <LogoutOutlined style={{ paddingRight: 5 }} /> Se déconnecter
        </Button>
      </div>
    </div>
  );
};

ProfilContent.propTypes = {
  setVisible: propTypes.func,
};

export function ProfilMenu() {
  const [visible, setVisible] = React.useState(false);
  const user = useSelector((state) => state.user.profil);
  function handleOpen() {
    setVisible(true);
  }
  function handleClose() {
    setVisible(false);
  }
  return (
    <React.Fragment>
      <Popover
        content={<ProfilContent setVisible={setVisible} />}
        placement="bottomLeft"
        onVisibleChange={handleClose}
        visible={visible}
      >
        <button
          style={{ display: "flex", alignSelf: "baseline" }}
          shape="circle"
          onClick={handleOpen}
          type="text"
          className="yc-box yc-nav-btn"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              size="large"
              className="yc-nav-avatar"
              src={isEmpty(user.photo) ? null : user.photo}
            >
              {isEmpty(user.photo) && (
                <UserOutlined style={{ color: "#000" }} />
              )}
            </Avatar>
            <i className="material-icons">expand_more</i>
          </div>
        </button>
      </Popover>
    </React.Fragment>
  );
}
