import {
  Avatar,
  Button,
  Col,
  List,
  Modal,
  notification,
  Row,
  Tabs,
} from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { SchoolTeacherLevels } from "./school";
import Switch from "react-switch";
import userHelper from "../../utils/helpers/userHelper";
import commitmentService from "../../services/commitmentService";
import searchHelper from "../../utils/helpers/searchHelper";

interface createTeacherLevel {
  user: any;
  title?: string;
}

interface levelListInterface {
  uid?: string;
  list: any[];
  setList?: any;
  levels: any[];
}

function SearchLevel(props: levelListInterface) {
  const { list, setList, levels } = props;
  console.warn(levels);

  const [loading, setLoading] = React.useState(false);
  const [isSchool, setIsSchool] = React.useState(true);

  const formikSchool = useFormik({
    initialValues: { cycle: null, section: null, option: null, classe: null },
    onSubmit: () => {
      console.log("ee");
    },
  });

  return (
    <div>
      <Row align="middle" gutter={4}>
        <Col>
          <span>{`Enseignant pour l'école`}</span>
        </Col>
        <Col>
          <Switch
            checked={isSchool}
            onChange={(checked) => setIsSchool(checked)}
            title="Enseignant pour l'école"
          />
        </Col>
      </Row>
      {isSchool && (
        <SchoolTeacherLevels
          loading={loading}
          setLoading={setLoading}
          formik={formikSchool}
          selecteds={list}
          setLevelSelection={setList}
        />
      )}
    </div>
  );
}

function LevelList(props: levelListInterface) {
  const { list, setList } = props;

  function handleRemove(item: any) {
    if (typeof setList === "function") {
      const elements = list.filter((p) => p.uid != item.uid);
      setList(elements);
    }
  }

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Button
          onClick={() => handleRemove(item)}
          danger
          style={{ width: "100%" }}
        >
          <span>{item.title}</span>
          <span style={{ paddingRight: 5, fontWeight: "bold" }}>
            {`: ${item?.groups?.name}`}
          </span>
        </Button>
      </List.Item>
    );
  };
  return (
    <div>
      <List dataSource={list} renderItem={renderItem} />
    </div>
  );
}

export function CreateLevel(props: createTeacherLevel) {
  const { title, user } = props;
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const levels = useSelector((state: RootState) => state.teacher.levels);
  const dispatch = useDispatch();

  function handleShow() {
    setVisible(true);
  }
  function handleClose() {
    setVisible(false);
  }

  async function handleSave() {
    setLoading(true);
    const teacher = userHelper.getTeacher(user);
    if (isEmpty(teacher)) {
      setLoading(false);
      notification.warn({
        message: "Sélection des données",
        description: "Impossible d'enregistrer",
      });
      return;
    }
    if (items.length === 0) {
      notification.warn({
        message: "Sélection des données",
        description: "Aucune donnée n'est sélectionnée.",
      });
      setLoading(false);
      return;
    }
    await Promise.all(
      items.map(async (p) => {
        const data = {
          teacher: `${teacher.uid}`,
          level: `${p.uid}`,
        };
        await setCommit(data);
      })
    );
    await searchHelper.getTeacherLevels(user, dispatch);
    notification.info({
      message: "Modification des classes",
      description: "Les classes sont enregistrées",
    });
    setVisible(false);
    setLoading(false);
  }

  async function setCommit(data: any) {
    let response = false;
    await commitmentService
      .store(data)
      .then(() => {
        response = true;
      })
      .catch((reason) => {
        console.warn(commitmentService.getError(reason));
      });
    return response;
  }

  function getCount(list: any[]): string {
    let response = "0";
    const len = list.length;
    if (len > 99) {
      response = "+99";
    } else {
      response = `${len}`;
    }
    return response;
  }
  return (
    <React.Fragment>
      <Button onClick={handleShow} title={title}>
        {isEmpty(title) ? "Définissez les classes de prestation" : title}
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        visible={visible}
        centered
        confirmLoading={loading}
        okButtonProps={{ disabled: items.length === 0 || loading }}
        cancelButtonProps={{ disabled: loading }}
        onCancel={handleClose}
        okText="Valider"
        cancelText="Annuler"
        title="Choix des classes"
        onOk={handleSave}
      >
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          <Tabs>
            <Tabs.TabPane key="0" animated tab="Recherche de classe">
              <SearchLevel levels={levels} list={items} setList={setItems} />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="1"
              animated
              tab={
                <div style={{ padding: "5px" }}>
                  {`Votre séléction`}
                  <Avatar size={"small"}>{getCount(items)}</Avatar>
                </div>
              }
            >
              <LevelList
                uid={user?.teacher?.uid}
                list={items}
                setList={setItems}
                levels={[]}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Modal>
    </React.Fragment>
  );
}
