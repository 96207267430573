import { Avatar, Button, Col, Progress, Row, Typography } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

export function ProfilValue({
  title = "",
  value = "",
  onUpdate,
  updateComponent = null,
  Icon = null,
  rate = 0,
}) {
  return (
    <Row gutter={16} wrap={false}>
      <Col>
        {!isEmpty(Icon) && (
          <Avatar className="yc-value-avatar">
            <Icon />
          </Avatar>
        )}
      </Col>
      <Col flex={1}>
        <Typography.Title
          level={3}
          style={{
            fontSize: 16,
            margin: 0,
            fontFamily: "popins-black",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography.Title>
        {!rate > 0 && (
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {value}
          </Typography.Paragraph>
        )}
        {rate > 0 && (
          <Progress
            percent={rate}
            status={
              rate >= 60
                ? "success"
                : rate >= 50 && rate < 60
                ? "normal"
                : "exception"
            }
          />
        )}
      </Col>
      <Col>
        {typeof onUpdate === "function" && (
          <Button type="link" onClick={onUpdate} style={{ fontSize: 12 }}>
            Modifier
          </Button>
        )}
      </Col>
      {!isEmpty(updateComponent) && <Col>{updateComponent}</Col>}
    </Row>
  );
}

ProfilValue.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  Icon: PropTypes.element,
  rate: PropTypes.number,
  onUpdate: PropTypes.any,
  updateComponent: PropTypes.element,
};
