import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DlInput, DlSelect } from "../../components/input";
import * as yup from "yup";
import { phoneRegex } from "./../login";
import studentService from "../../services/studentService";
import { getUid } from "../../utils/helpers/title";
import supportService from "../../services/supportService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helpers/userHelper";
import { isEmpty, toString } from "lodash";
import {
  setResponsibleChildren,
  setStudentResponsibles,
} from "../../redux/supportReducer";
import propTypes from "prop-types";
import { UsergroupAddOutlined } from "@ant-design/icons";
import relationshipValues from "../../relationships";
import responsibleService from "../../services/responsibleService";
import { setBlockUser } from "../../redux/userReducer";

export function CreateChild({
  type = "default",
  isResponsible = true,
  block = false,
}) {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profil);
  const dispatch = useDispatch();
  let relationships: any[] = relationshipValues;
  if (!isResponsible) {
    relationships = [];
    relationshipValues.forEach((p: any) => {
      relationships.push({
        value: p.value,
        title: p.child,
      });
    });
  }
  const formik = useFormik({
    initialValues: { relationship: "", phone: "" },
    onSubmit: handleCreate,
    validationSchema: yup.object({
      relationship: yup.string().required("Le champ est requis"),
      phone: yup
        .string()
        .required("Le champ est requis")
        .matches(phoneRegex, { message: "Ecrivez correctement votre numéro" }),
    }),
  });

  function handleShow() {
    setVisible(true);
  }
  function handleClose() {
    setVisible(false);
  }

  async function isAvaillableAdd(query: string) {
    let response = null;
    await supportService
      .getByKey(query)
      .then((p) => {
        const data = supportService.getData(p);
        response = data.length === 0;
      })
      .catch(() => {
        response = null;
      });
    return response;
  }

  async function saveAsResponsible() {
    const data = formik.values;
    setLoading(true);
    let responsible = userHelper.getResponsible(user);
    if (responsible === null) {
      notification.error({
        message: "Oups",
        description: "L'utilisateur connecté n'est pas un responsable",
      });
      setLoading(false);
      return;
    } else {
      responsible = getUid(responsible["@id"]);
    }
    const test = await isAvaillableAdd(
      `student.users.phone=${data.phone.replace(
        "+",
        ""
      )}&responsible=${responsible}`
    );
    if (test === null) {
      notification.error({
        message: "Vérification de relation",
        description:
          "Nous n'avons pas pu vérifier la possibilité de création de cette relation, veuillez réessayer",
      });
      setLoading(false);
      return;
    } else if (test === false) {
      notification.error({
        message: "Relation entre responsable et étudiant",
        description: "Cette relation existe déjà",
      });
      setLoading(false);
      return;
    }
    await studentService
      .getByKey(`users.phone=${data.phone.replace("+", "")}`)
      .then(async (response) => {
        const items = studentService.getData(response);
        if (items.length > 0) {
          const rep = await handleSaveSupport(
            items,
            toString(responsible),
            true
          );
          if (rep) {
            setVisible(false);
            //eslint-disable-next-line @typescript-eslint/no-empty-function
            formik.handleReset(() => {});
            setLoading(false);
          }
        } else {
          notification.warning({
            message: "Ajout de responsable",
            description:
              "Nous n'avons pas trouvé un responsable avec ce numéro. Veuillez vérifier le numéro.",
          });
        }
      })
      .catch((reason) => {
        console.warn(reason);
        notification.warning({
          message: "",
          description: "Veuillez réessayer votre demande",
        });
      });
    setLoading(false);
  }

  async function saveAsStudent() {
    const data = formik.values;
    setLoading(true);
    let student = userHelper.getStudent(user);
    if (student === null) {
      notification.error({
        message: "Oups",
        description: "L'utilisateur connecté n'est pas un étudiant",
      });
      setLoading(false);
      return;
    } else {
      student = getUid(student["@id"]);
    }
    const test = await isAvaillableAdd(
      `responsible.users.phone=${data.phone.replace(
        "+",
        ""
      )}&student=${student}`
    );
    if (test === null) {
      notification.error({
        message: "Vérification de relation",
        description:
          "Nous n'avons pas pu vérifier la possibilité de création de cette relation, veuillez réessayer",
      });
      setLoading(false);
      return;
    } else if (test === false) {
      notification.error({
        message: "Relation entre responsable et étudiant",
        description: "Cette relation existe déjà",
      });
      setLoading(false);
      return;
    }
    await responsibleService
      .getByKey(`users.phone=${data.phone.replace("+", "")}`)
      .then(async (response) => {
        const items = responsibleService.getData(response);
        if (items.length > 0) {
          const rep = await handleSaveSupport(items, toString(student), false);
          if (rep) {
            if (block) {
              dispatch(setBlockUser(false));
            }
            setVisible(false);
            //eslint-disable-next-line @typescript-eslint/no-empty-function
            formik.handleReset(() => {});
            setLoading(false);
          }
        } else {
          notification.warning({
            message: isResponsible
              ? "Ajout de l'étudiant"
              : "Ajout de responsable",
            description: `Nous n'avons pas trouvé ${
              isResponsible ? "un étudiant" : "un responsable"
            } avec ce numéro. Veuillez vérifier le numéro.`,
          });
        }
      })
      .catch((reason) => {
        console.warn(reason.response);
        notification.warning({
          message: "",
          description: "Veuillez réessayer votre demande",
        });
      });
    setLoading(false);
  }

  async function handleCreate() {
    if (isResponsible) {
      saveAsResponsible();
    } else {
      saveAsStudent();
    }
  }
  async function handleSaveSupport(
    items: any[],
    responsible: string,
    isResponsible = true
  ) {
    let rep = false;
    if (items.length === 0) return rep;
    const item = items[0];
    const id = getUid(item["@id"]);
    const data = {
      relationship: formik.values.relationship,
      responsible: `/api/responsibles/${responsible}`,
      student: `/api/students/${id}`,
      status: "in_pending",
    };
    if (!isResponsible) {
      data.responsible = `/api/responsibles/${id}`;
      data.student = `/api/students/${responsible}`;
      data.status = "child_in_pending";
    }
    await supportService
      .store(data)
      .then(() => {
        if (isResponsible) {
          supportService
            .getByKey(`responsible=${responsible}`)
            .then((response) => {
              const data = supportService.getData(response);
              dispatch(setResponsibleChildren(data));
            })
            .catch((reason) => {
              console.warn(reason);
            });
        } else {
          supportService
            .getByKey(`student=${responsible}`)
            .then((response) => {
              const data = supportService.getData(response);
              dispatch(setStudentResponsibles(data));
            })
            .catch((reason) => {
              console.warn(reason);
            });
        }
        rep = true;
        notification.info({
          message: "Relation entre responsable et étudiant",
          description: "La rélation est établie avec succès",
        });
      })
      .catch((reason) => {
        console.warn(reason);
        notification.error({
          message: "Relation entre responsable et étudiant",
          description:
            "Nous n'avons pu établir la relation, veuillez réessayer.",
        });
      });
    return rep;
  }

  React.useEffect(() => {
    if (relationships.findIndex((p) => isEmpty(p.value)) === -1) {
      relationships.unshift({
        title: "séléctionner un type de relation",
        value: "",
      });
    }
  }, []);
  return (
    <React.Fragment>
      <Button
        title="Ajouter un enfant"
        onClick={handleShow}
        type="primary"
        shape={type === "circle" ? "circle" : "default"}
      >
        {type === "default" &&
          `${isResponsible ? "Ajouter un enfant" : "Ajouter un responsable"}`}
        {type === "circle" && <UsergroupAddOutlined />}
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        visible={visible}
        centered
        onCancel={handleClose}
        okText="Ajouter"
        cancelText="Annuler"
        onOk={() => formik.handleSubmit()}
        confirmLoading={loading}
        title="Ajouter une relation"
        style={{ zIndex: 9999999 }}
      >
        <div>
          <DlInput
            type="tel"
            formik={formik}
            label={
              isResponsible
                ? "Le numéro de l'enfant"
                : "Le numéro de votre responsable"
            }
            name="phone"
            placeholder={"+243000000000"}
          />
          <DlSelect
            label={
              isResponsible
                ? "Type de relation avec l'enfant"
                : "Type de relation votre responsable"
            }
            formik={formik}
            list={relationships}
            name="relationship"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}

CreateChild.propTypes = {
  type: propTypes.string,
  isResponsible: propTypes.bool,
  block: propTypes.bool,
};
