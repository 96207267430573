import { Skeleton } from "antd";
import React from "react";

export function Loader() {
  return (
    <div>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );
}
