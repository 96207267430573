import React from "react";
import { Input, Typography } from "antd";
import { ErrorAlert } from "../error";
import PropTypes from "prop-types";

const { Text } = Typography;

export default function App({
  label,
  placeholder,
  name = "",
  type = "text",
  enabled = true,
  formik = {},
}) {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        marginTop: 15,
        marginBottom: 5,
      }}
      className={
        formik.errors[name] && formik.touched[name] ? "yc-input-shake" : ""
      }
    >
      <Text className="yc-input-label">{label}</Text>
      <Input
        placeholder={placeholder}
        type={type}
        style={{ borderRadius: 5 }}
        disabled={!enabled}
        value={formik.values[name]}
        onChange={formik.handleChange}
        name={name}
        className="yc-input"
      />
      {formik.errors[name] && formik.touched[name] && (
        <ErrorAlert message={formik.errors[name]} />
      )}
    </div>
  );
}

App.propTypes = {
  label: PropTypes.string,
  formik: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  enabled: PropTypes.bool,
};
