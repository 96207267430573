import { Avatar, Col, Row, Typography } from "antd";
import React from "react";
import prop from "prop-types";
import { isEmpty } from "lodash";

export function ItemCard({
  title = "",
  count = 0,
  Icon,
  bordered = true,
  color = "gold",
}) {
  return (
    <Row
      wrap={false}
      align="middle"
      style={{ borderBottom: bordered ? "dashed 1px" : "none", padding: 10 }}
      gutter={8}
    >
      <Col>
        {!isEmpty(Icon) && (
          <Avatar style={{ background: color }}>
            <Icon />
          </Avatar>
        )}
      </Col>
      <Col flex={1}>
        <Typography.Text>{title}</Typography.Text>
      </Col>
      <Col>
        {count > 0 && (
          <Typography.Text ellipsis strong>{`+ ${count}`}</Typography.Text>
        )}
      </Col>
    </Row>
  );
}

ItemCard.propTypes = {
  title: prop.string,
  count: prop.number,
  Icon: prop.element,
  bordered: prop.bool,
  color: prop.string,
};
