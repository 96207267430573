import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DlInput } from "../../components/input";
import * as yup from "yup";
import userService from "../../services/userService";
import { getUid } from "../../utils/helpers/title";
import { toString } from "lodash";
import userHelper from "../../utils/helpers/userHelper";
import { useDispatch } from "react-redux";

interface updateUserInterface {
  user: any;
}

export function UpdateNameProfil(props: updateUserInterface = { user: {} }) {
  const user = props.user;

  React.useEffect(() => {
    const { firstName, lastName } = user;
    formik.setValues({ firstName, lastName });
  }, []);

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: props.user.firstName,
      lastName: user?.lastName,
    },
    onSubmit: handleUpdate,
    validationSchema: yup.object({
      firstName: yup.string().required("Champ requis"),
      lastName: yup.string().required("Champ requis"),
    }),
  });
  async function handleUpdate() {
    const data = formik.values;
    const uid = getUid(user["@id"]);
    setLoading(true);
    await userService
      .update(toString(uid), data)
      .then((response) => {
        const data = response.data;
        userHelper.setData(data, dispatch);
        notification.info({
          message: "Modifcation de nom",
          description: "La modificatio a réussi",
        });
        setLoading(false);
        setVisible(false);
        formik.handleReset(() => {
          console.log("");
        });
      })
      .catch((reason) => console.warn(reason));
    setLoading(false);
  }
  function handleOpen() {
    setVisible(true);
  }
  function handleClose() {
    if (loading) {
      return;
    }
    setVisible(false);
    formik.handleReset(() => {
      console.log("");
    });
  }
  return (
    <React.Fragment>
      <Button onClick={handleOpen} style={{ fontSize: 14 }} type="link">
        Modifier
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        visible={visible}
        onCancel={handleClose}
        okText="Modifier"
        cancelText="Annuler"
        centered
        onOk={() => formik.handleSubmit()}
        title="Modifier de nom"
        confirmLoading={loading}
      >
        <div>
          <DlInput
            formik={formik}
            name="lastName"
            placeholder="votre nom"
            label="Votre nom"
          />
          <DlInput
            formik={formik}
            name="firstName"
            label="Votre prénom"
            placeholder="votre prénom"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
