import { Image, Typography } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { productInterface } from "../navigation/products";

export function ProductItem(props: productInterface) {
  const { content, image, title, url } = props;
  return (
    <div
      className="yc-box-shadow-hover"
      style={{ border: "#f1f1f1 solid 0.2px", padding: 5, margin: 3 }}
    >
      <a href={isEmpty(url) ? "#" : url}>
        <Typography.Title
          level={2}
          style={{ padding: "5px 0", fontSize: 18, fontWeight: "700" }}
        >
          {title}
        </Typography.Title>
        <Image src={image} preview={false} />
        <Typography.Paragraph
          style={{ textAlign: "justify" }}
          ellipsis={{ rows: 3 }}
        >
          {content}
        </Typography.Paragraph>
      </a>
    </div>
  );
}
