import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface userData {
  profil: subjectInterface;
  current: subjectInterface;
  list: subjectInterface[];
  all: subjectInterface[];
  filter: subjectFilterInterface;
}

export interface subjectFilterInterface {
  "order[title]"?: "asc" | "desc" | null;
  title?: string;
  level?: string;
}

export interface subjectInterface {
  "@context"?: string;
  "@id"?: string;
  "@type"?: string;
  title: string;
  category?: string;
  level: string;
  lessons: any[];
  state: string;
  description?: string;
  poster?: string;
}

const initialState: userData = {
  profil: { lessons: [], level: "", state: "", title: "" },
  current: { lessons: [], level: "", state: "", title: "" },
  list: [],
  all: [],
  filter: {},
};

export const subjectSlice = createSlice({
  name: "subject",
  initialState,
  reducers: {
    setCurrentSubject: (state, action: PayloadAction<subjectInterface>) => {
      state.current = action.payload;
    },
    setSubjects: (state, action: PayloadAction<subjectInterface[]>) => {
      state.list = action.payload;
    },
    setDashboardSubjects: (
      state,
      action: PayloadAction<subjectInterface[]>
    ) => {
      state.all = action.payload;
    },
    setSubjectFilter: (
      state,
      action: PayloadAction<subjectFilterInterface>
    ) => {
      state.filter = action.payload;
    },
  },
});
export const {
  setCurrentSubject,
  setSubjectFilter,
  setDashboardSubjects,
  setSubjects,
} = subjectSlice.actions;
export default subjectSlice.reducer;
