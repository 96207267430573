import { ColumnsType } from "antd/lib/table";

export declare type paymentType =
  | "subscription"
  | "schoolBook"
  | "womenContest"
  | "schoolKits"
  | "machine"
  | undefined;

const colums: ColumnsType<object> = [
  {
    title: "Type d'abonnement",
    dataIndex: "title",
    align: "center",
  },
  {
    title: "Montant",
    dataIndex: "amount",
    align: "center",
  },
  {
    title: "Date de paiement",
    dataIndex: "date",
    align: "center",
  },
];
export { colums as PaymentColumns };
