import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setForecatCurrentPage } from "../redux/forecatReducer";
import { RootState } from "../redux/store";
import dateHelpers from "../utils/helpers/dateHelpers";
import searchHelper from "../utils/helpers/searchHelper";
import userHelper from "../utils/helpers/userHelper";

export function TeacherAdmin() {
  const commitments = useSelector((state: RootState) => state.teacher.levels);
  const user = useSelector((state: RootState) => state.user.profil);
  const dispatch = useDispatch();

  async function searchManagements(user: any) {
    if (userHelper.isTeacher(user)) {
      let teacher = userHelper.getTeacher(user);
      if (teacher !== null) {
        teacher = teacher["@id"];
      } else {
        return;
      }
      await searchHelper.getTeacherManagement(user, dispatch);
    }
  }
  async function init() {
    searchManagements(user);
  }
  React.useEffect(() => {
    init();
  }, [user]);

  React.useEffect(() => {
    searchHelper.getForecast(commitments, dispatch, 1);
    dispatch(setForecatCurrentPage(1));
    const week = dateHelpers.getCurrentWeek();
    searchHelper.getWeekForecast(week, commitments, dispatch);
  }, [commitments]);

  return <React.Fragment></React.Fragment>;
}
