import { Layout } from "antd";
import React from "react";
import PropTypes from "prop-types";

export function HeaderProfil(props) {
  const colors = ["#000a", "#0004"];
  const [background, setBackground] = React.useState("");
  React.useEffect(() => {
    let color = "";
    colors.forEach((p, i) => {
      color += p + (i < colors.length - 1 ? "," : "");
    });
    const element = `linear-gradient(${color}),url(${require("../../assets/images/yec-group.jpg")})`;
    setBackground(element);
  }, []);
  return (
    <React.Fragment>
      <Layout.Content
        className="yc-profil-header"
        style={{
          background: background,
          display: "flex",
          alignItems: "end",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100px",
        }}
      >
        {props.children}
      </Layout.Content>
    </React.Fragment>
  );
}

HeaderProfil.propTypes = {
  children: PropTypes.element,
};
