import axios from "axios";
//import config from "./config";

export default axios.create({
  //baseURL: config.URL_API,
  baseURL:
    process.env.REACT_APP_ENV == "rec"
      ? process.env.REACT_APP_URL_REC_API
      : process.env.REACT_APP_ENV == "int"
      ? process.env.REACT_APP_URL_INT_API
      : process.env.REACT_APP_URL_PROD_API,
  headers: {
    "Content-Type": "application/json",
  },
});
