import { DislikeFilled, LikeFilled } from "@ant-design/icons";
import { Button, Layout, List, notification, PageHeader } from "antd";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserAvatar } from "../../components/card/userAvatar";
import { RootState } from "../../redux/store";
import { setStudentTeachers } from "../../redux/studentReducer";
import managementService from "../../services/managementService";
import { getUid } from "../../utils/helpers/title";
import { CreateStudent } from "../responsibility/createStudentMan";

interface respInterface {
  type?: "setting";
}

export function StudentTeachersProfil(props: respInterface) {
  const { type } = props;
  const teachers = useSelector((state: RootState) => state.student.teachers);
  const user = useSelector((state: RootState) => state.user.profil);

  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  async function init() {
    const uid = user.student?.uid;
    if (isEmpty(uid)) {
      return;
    }
    const query = `student=${getUid(
      uid
    )}&status[]=in_pending&status[]=validated&status[]=student_in_pending&order[teacher.users.firstName]=asc`;
    await managementService
      .getByKey(query)
      .then((response) => {
        const data = managementService.getData(response);
        dispatch(setStudentTeachers(data));
      })
      .catch((reason) => console.warn(reason));
  }

  async function handleConfirm(item: any) {
    setLoading(true);
    await managementService
      .update(getUid(item["@id"]), { status: `validated` })
      .then(async () => {
        await init();
        notification.info({
          message: "modification de status",
          description: "Vous avez confirmé cette relation",
        });
      })
      .catch(() => {
        notification.error({
          message: "modification de status",
          description: "La requête n'a pas abouti",
        });
      });
    setLoading(false);
  }
  async function handleReject(item: any) {
    setLoading(true);
    await managementService
      .update(getUid(item["@id"]), { status: `student_reject` })
      .then(async () => {
        await init();
        notification.info({
          message: "modification de status",
          description: "Vous avez rejeté cette relation",
        });
      })
      .catch(() => {
        notification.error({
          message: "modification de status",
          description: "La requête n'a pas abouti",
        });
      });
    setLoading(false);
  }

  const renderItem = (item: any, index: number) => {
    return (
      <List.Item>
        <UserAvatar
          title={`${upperFirst(item?.teacher?.users?.firstName)} ${upperCase(
            item?.teacher?.users?.lastName
          )}`}
          subtitle="Votre enseignant"
          photo={item?.teacher?.users?.photo}
          key={`teacher${index}`}
          buttons={[
            item.status === "in_pending" && (
              <Button
                loading={item.load}
                type="primary"
                onClick={() => handleConfirm(item)}
              >
                <LikeFilled color="blue" />
              </Button>
            ),
            item.status === "in_pending" && (
              <Button danger onClick={() => handleReject(item)}>
                <DislikeFilled color="red" />
              </Button>
            ),
          ]}
        />
      </List.Item>
    );
  };

  React.useEffect(() => {
    if (teachers.length === 0) {
      //init();
    }
  }, []);

  return (
    <React.Fragment>
      <Layout
        className="yc-box"
        style={{ minHeight: "100vh", padding: "20px" }}
      >
        {type === "setting" && (
          <PageHeader
            onBack={() => window.history.back()}
            title="Vos enseignants"
            extra={[
              <CreateStudent key="create" isResponsible={false} init={init} />,
            ]}
          />
        )}
        <div style={{ padding: "20px 20px" }}>
          <List
            dataSource={teachers}
            renderItem={renderItem}
            grid={{ md: 4, xs: 1, gutter: 16 }}
            loading={loading}
          />
        </div>
      </Layout>
    </React.Fragment>
  );
}
