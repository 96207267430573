import { Col, Image, Row, Typography } from "antd";
import React from "react";

interface meInterface {
  forecat: any;
}

export function DomaineForecast(props: meInterface) {
  const { forecat } = props;

  return (
    <Row
      gutter={32}
      align="middle"
      justify="center"
      style={{ minHeight: "80vh", width: "100%", padding: "20px 10px" }}
    >
      <Col md={12} xs={24} style={{ textAlign: "center" }}>
        <Image
          style={{ maxWidth: "500px" }}
          src={forecat.subject?.poster}
          preview={false}
        />
      </Col>
      <Col md={12} xs={24} style={{ textAlign: "center" }}>
        <Typography.Title>
          <div style={{ color: "blue" }}>
            <span>{`Domaine : `}</span>
            {forecat?.subject?.title}
          </div>
          <div>
            {`Titre: `}
            <span>{forecat?.title}</span>
          </div>
        </Typography.Title>
      </Col>
      <Col md={12} xs={24}>
        <Typography.Paragraph style={{ textAlign: "justify" }}>
          {forecat?.subject?.description}
        </Typography.Paragraph>
      </Col>
    </Row>
  );
}
