import { Button, Layout, List, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import rules from "../rules";
import { setAppTitle } from "../utils/helpers/title";

const { Paragraph, Text, Title } = Typography;

export function PrivacyScreen() {
  setAppTitle("Termes et conditions");

  const renderItem = (item: any, index: number) => {
    return (
      <article>
        <Title level={2}>{`Article ${index + 1}: ${item.title}`}</Title>
        <Paragraph style={{ textAlign: "justify" }}>{item.content}</Paragraph>
      </article>
    );
  };

  return (
    <Layout
      className="yc-box"
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        padding: 10,
        overflowX: "hidden",
        overflowY: "visible",
      }}
    >
      <Title
        style={{ textAlign: "center" }}
      >{`Conditions générales d'utilisation du site`}</Title>
      <Text style={{ textAlign: "center", marginTop: -25, marginBottom: 20 }}>
        En vigueur au (indiquer la date)
      </Text>
      <article>
        <Paragraph style={{ textAlign: "justify" }}>
          {`Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des
modalités de mise à disposition du site et des services par (indiquer le nom du site) et de définir les
conditions d’accès et d’utilisation des services par « l'Utilisateur ».
Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
Les CGU doivent être acceptées par tout Utilisateur souhaitant accéder au site. Elles constituent le contrat
entre le site et l'Utilisateur. L’accès au site par l’Utilisateur signifie son acceptation des présentes CGU. Il
s’engage désormais à respecter les présentes conditions.
Ou si le site comprend un espace membre :
Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes
CGU par l’utilisateur. Lors de l'inscription sur le site via le Formulaire d’inscription, chaque utilisateur
accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir
lu et compris les CGU et je les accepte ».
En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à
l'accès des services proposés par le site.
(indiquer le nom du site) se réserve le droit de modifier unilatéralement et à tout moment le contenu des
présentes CGU.`}
        </Paragraph>
      </article>
      <List
        dataSource={rules.sort((a, b) => a.position - b.position)}
        renderItem={renderItem}
      />
      <div style={{ position: "fixed", top: 60, right: 30 }}>
        <Button type="primary">
          <Link to="/home/register">{"S'inscrire"}</Link>
        </Button>
      </div>
    </Layout>
  );
}
