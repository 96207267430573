interface relationshipInterface {
  title: string;
  value: string;
  fr?: string;
  eng?: string;
  child?: string;
}

const relationshipValues: relationshipInterface[] = [];
relationshipValues.push({
  title: "Votre enfant",
  value: "child",
  child: "Vous êtes enfant",
});
relationshipValues.push({
  title: "Votre neveu",
  value: "nephew",
  child: "Vous êtes un neveu",
});
relationshipValues.push({
  title: "Votre nièce",
  value: "niece",
  child: "Vous êtes une nièce",
});
relationshipValues.push({
  title: "Autres",
  value: "other",
  child: "Autres relations",
});

export default relationshipValues;
