import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface subscriptionData {
  current: any; // current subscription
  list: any[]; //subscriptions list
  all: any[]; //all subscriptions about current user
}

const initialState: subscriptionData = {
  current: {},
  list: [],
  all: [],
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setAllUserSubscriptions: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setSubscriptions: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setCurrentSubscription: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
  },
});
export const {
  setAllUserSubscriptions,
  setSubscriptions,
  setCurrentSubscription,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
