import { setProfilReadings } from "../../redux/readingReducer";
import { setProfil, setUserSubscription } from "../../redux/userReducer";
import readingService from "../../services/readingService";
import { isEmpty } from "lodash";
import { getUid } from "./title";
import { userType } from "../../routes";
import userService from "../../services/userService";
import subscriptionService from "../../services/subscriptionService";
import dateHelpers from "./dateHelpers";

class UserHelper {
  private key = "yeccolapp_user";
  private tokenKey = "yeccolapp_user_token";

  getToken(): string | null {
    let response = null;
    const item = localStorage.getItem(this.tokenKey);
    console.log("TOKEN", item);
    if (typeof item === "string") {
      response = item;
    }
    return response;
  }

  setToken(token: string) {
    localStorage.setItem(this.tokenKey, token);
  }

  getData(): any | null {
    let response: any = null;
    const item = localStorage.getItem(this.key);
    if (typeof item === "string") {
      response = JSON.parse(item);
    }
    return response;
  }

  isConnected() {
    const user = this.getData();
    return user !== null;
  }

  async refreshUser() {
    let value = false;
    const user = this.getData();
    if (user !== null) {
      const code = user.code;
      await userService
        .login({ username: code, password: code })
        .then((response) => {
          const data = response.data;
          this.setToken(data.token);
          value = true;
        })
        .catch((reason) => {
          console.warn(reason);
        });
    }
    return value;
  }

  isStudent(user: any): boolean {
    return !isEmpty(user.student);
  }
  isResponsible(user: any): boolean {
    return !isEmpty(user.responsible);
  }
  isTeacher(user: any): boolean {
    return !isEmpty(user.teacher);
  }
  getResponsible(user: any): any | null {
    if (!this.isResponsible(user)) {
      return null;
    }
    return user.responsible;
  }

  getStudent(user: any): any | null {
    if (!this.isStudent(user)) {
      return null;
    }
    return user.student;
  }

  getTeacher(user: any): any | null {
    if (!this.isTeacher(user)) {
      return null;
    }
    return user.teacher;
  }

  isVisible(
    values: userType[],
    user: any,
    subscribeable: boolean,
    subscriptionValid: boolean,
  ) {
    let response = false;
    if (
      values.findIndex((p) => p === "responsible") != -1 &&
      this.isResponsible(user)
    ) {
      response = true;
    } else if (
      values.findIndex((p) => p === "student") != -1 &&
      this.isStudent(user)
    ) {
      response = true;
    } else if (
      values.findIndex((p) => p == "teacher") != -1 &&
      this.isTeacher(user)
    ) {
      response = true;
    }
    if (subscribeable && !subscriptionValid) {
      response = false;
    }
    return response;
  }

  setData(item: object, dispatch: any) {
    if (typeof dispatch === "function") {
      dispatch(setProfil(item));
    }
    localStorage.setItem(this.key, JSON.stringify(item));
  }

  setLogout(dispatch: any) {
    if (typeof dispatch === "function") {
      dispatch(setProfil({}));
    }
    localStorage.removeItem(this.key);
    localStorage.removeItem(this.tokenKey);
  }

  async setReading(dispatch: any) {
    const user = this.getData();
    if (typeof dispatch === "function" && !isEmpty(user)) {
      if (isEmpty(user.student)) {
        return;
      }
      await readingService
        .getByKey(
          `student=${getUid(user.student["@id"])}&order[createdAt]=desc`,
        )
        .then((response) => {
          const data = readingService.getData(response);
          dispatch(setProfilReadings(data));
        })
        .catch((reason) => {
          console.warn(reason);
        });
    }
  }

  async getSubscriptionState(dispatch?: any) {
    let item = null;
    const user = this.getData();
    const query = `user=${user.uid}&type=subscription&status=in_progress&order[validatedAt]=desc`;
    await subscriptionService
      .getByKey(query)
      .then((response) => {
        const data = subscriptionService.getData(response);
        if (data.length > 0) {
          item = data[0];
          if (typeof dispatch === "function") {
            dispatch(setUserSubscription(item));
          }
        }
      })
      .catch(() => {
        item = null;
      });
    return item;
  }

  getSubscriptionValidation(subscription: any) {
    let value = "L'abonnement n'est pas valide";
    const { now, validatedAt } = subscription;
    if (!isEmpty(now) && !isEmpty(validatedAt)) {
      const days = dateHelpers.getDifference(validatedAt, now, "days");
      if (days > 1) {
        value = `Reste ${days} jours d'abonnement`;
      } else if (days === 0) {
        value = `Le dernier jour d'abonnement`;
      } else if (days === 1) {
        value = `Valide jusqu'à demain à ${dateHelpers.getDate(
          validatedAt,
          "HH[h]mm",
        )}`;
      }
    }
    return value;
  }
}

export default new UserHelper();
