import { Layout, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";

export function FolloWedLessonPofil() {
  const colums: ColumnsType<object> = [
    {
      title: "Titre de la leçon",
      dataIndex: "title",
    },
    {
      title: "Type de contenu",
      dataIndex: "type",
    },
    {
      title: "Nombre de lecture",
      dataIndex: "reading",
    },
  ];

  return (
    <React.Fragment>
      <Layout className="yc-box" style={{ minHeight: "100vh" }}>
        <Table className="yc-table" columns={colums} />
      </Layout>
    </React.Fragment>
  );
}
