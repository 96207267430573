import { Button, Layout, Popover, Row, Skeleton, Tabs } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreatePayment } from "../components/createPayment";
import { RootState } from "../redux/store";
import {
  setUserAllPayments,
  setUserAllSubscriptions,
  setUserOtherPayments,
} from "../redux/userReducer";
import subscriptionService from "../services/subscriptionService";
import { getPaymentListTitle } from "../utils/helpers/title";
import { AllPayments } from "./payments/all";
import { OtherPayments } from "./payments/other";
import { SubscriptionPayments } from "./payments/subscription";

export function PaymentListScreen() {
  const all = useSelector((state: RootState) => state.user.payments);
  const subscriptions = useSelector(
    (state: RootState) => state.user.subscriptions
  );
  const others = useSelector((state: RootState) => state.user.otherPayments);
  const user = useSelector((state: RootState) => state.user.profil);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  async function init() {
    if (
      subscriptions.length === 0 ||
      (all.length === 0 && others.length === 0)
    ) {
      setLoading(true);
    }
    await subscriptionService
      .getByKey(`user=${user.uid}&order[validatedAt]=asc&limit=100`)
      .then((response) => {
        const data = subscriptionService.getData(response);
        dispatch(setUserAllPayments(data));
      })
      .catch(() => console.log(""));
    await subscriptionService
      .getByKey(
        `user=${user.uid}&order[validatedAt]=asc&limit=100&type=subscription`
      )
      .then((response) => {
        const data = subscriptionService.getData(response);
        dispatch(setUserAllSubscriptions(data));
      })
      .catch(() => console.log(""));
    let query = `user=${user.uid}&order[validatedAt]=asc&limit=100`;
    getPaymentListTitle().forEach((p) => {
      if (p.value !== "subscription") {
        query += `&type[]=${p.value}`;
      }
    });
    await subscriptionService
      .getByKey(query)
      .then((response) => {
        const data = subscriptionService.getData(response);
        dispatch(setUserOtherPayments(data));
      })
      .catch(() => console.log(""));
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, [user]);

  function handleShow() {
    setVisible(true);
  }
  function handleHide() {
    setVisible(false);
  }

  return (
    <Layout
      style={{ background: "#fff", padding: "20px 10px", minHeight: "100vh" }}
    >
      <Row style={{ padding: 10 }} justify="end">
        <Popover
          content={
            <div>
              {getPaymentListTitle().map((p, index) => {
                return (
                  <div key={`payment${index}`}>
                    <CreatePayment
                      payer={user}
                      user={user}
                      title={p.title}
                      type={p.value}
                      setVisble={setVisible}
                    />
                  </div>
                );
              })}
            </div>
          }
          visible={visible}
          onVisibleChange={handleHide}
        >
          <Button
            onClick={handleShow}
            type="primary"
          >{`Ajouter un paiment`}</Button>
        </Popover>
      </Row>
      {!loading && (
        <Tabs
          className="yc-tabs-card"
          style={{ padding: "10px" }}
          centered
          type="card"
        >
          <Tabs.TabPane tab="Tous les paiements" key="0">
            <AllPayments />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Les souscriptions" key="1">
            <SubscriptionPayments />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Autres" key="2">
            <OtherPayments />
          </Tabs.TabPane>
        </Tabs>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
    </Layout>
  );
}
