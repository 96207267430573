const merchantID = "6216ac70863149e2b0c2cd2354001870";
const merchantPassword = "cdaf7f8a697343e99e9b295c349a4f69";
const merchantApi = "https://api.maxicashapp.com/PayEntryPost";
const merchantEmail = "henockmassamba@gmail.com";
/**TEST */
// const merchantID = "1f134f9556e54d1893adb6901f4a410c";
// const merchantPassword = "8e397cc441ab4395b444aed771dae3be";
// const merchantApi = "https://api-testbed.maxicashapp.com/PayEntryPost";

export { merchantID, merchantPassword, merchantEmail, merchantApi };
