import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Layout, Result, Skeleton, Typography } from "antd";
import { toString } from "lodash";
import React from "react";
import { useSearchParams } from "react-router-dom";
import subscriptionService from "../services/subscriptionService";
import { getUid, refKey } from "../utils/helpers/title";

const { Text, Paragraph } = Typography;

interface errorViewInterface {
  type: string;
  item: any;
}

function ErrorView(props: errorViewInterface) {
  const { item, type } = props;
  return (
    <React.Fragment>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Result
        status="error"
        title="Le paiement a échoué"
        subTitle="Vous pouvez réessayer"
        extra={[
          <Button type="primary" key="console">
            <a href={item?.back}>Revenir</a>
          </Button>,
          <Button key="buy">Payer encore</Button>,
        ]}
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              {`Le contenu que vous avez envoyé peut conteneur les erreurs suivantes :`}
            </Text>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
            {`Votre compte n'est pas suffisant pour payer`}
            <a>{type}</a>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
            {`Vous avez volontairement annulé la demande`} <a> </a>
          </Paragraph>
        </div>
      </Result>
    </React.Fragment>
  );
}

export function PaymentScreen() {
  const [params] = useSearchParams();
  const [type, setType] = React.useState<string | null>(null);
  const [format, setFormat] = React.useState<string | null>(null);
  const [paymentData, setPaymentData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    init();
  }, []);

  async function init() {
    setLoading(true);
    const urlType = params.get("type");
    const urlStatus = params.get("status");
    const urlFormat = params.get("format");
    setFormat(urlFormat);
    setType(params.get("type"));
    let item = null;
    const element = localStorage.getItem(refKey);
    if (element === null) {
      location.href = "/admin/";
    } else {
      item = JSON.parse(element);
    }
    setPaymentData(item);
    if (urlType === "success" && urlStatus === "success" && item != null) {
      await createSubscription(item);
    }
    setLoading(false);
  }

  async function createSubscription(item: any) {
    const { user, payer, reference, amount, currency, merchant } = item;
    const data = {
      payer: payer?.uid ?? getUid(payer["@id"]) ?? "",
      user: user?.uid ?? getUid(user["@id"]) ?? "",
      status: "in_progress",
      merchant,
      currency,
      amount: amount / 100,
      type: format ?? "subscription",
      paymentId: reference,
    };
    setLoading(true);
    await subscriptionService
      .store(data)
      .then(() => {
        localStorage.removeItem(refKey);
        new Notification("Paiement", {
          body: "Votre paiement a été effectué avec succès",
          icon: require("../assets/images/logo-image.png"),
        });
      })
      .catch((reason) =>
        console.log("ERROR paiement", reason?.response ?? reason),
      );
    setLoading(false);
  }

  return (
    <React.Fragment>
      {!loading && (
        <Layout>
          {type !== "success" && (
            <ErrorView type={toString(type)} item={paymentData} />
          )}
        </Layout>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
