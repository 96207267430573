import {
  CalendarOutlined,
  CameraFilled,
  FlagOutlined,
  MailOutlined,
  PartitionOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Layout,
  message,
  Row,
  Typography,
  Upload,
} from "antd";
import { isEmpty, upperCase, upperFirst } from "lodash";
import React from "react";
import { ProfilValue } from "../../components/profilValue";
import Prop from "prop-types";
import levelHelper from "../../utils/helpers/levelHelper";
import userService from "../../services/userService";
import { useDispatch } from "react-redux";
import userHelper from "../../utils/helpers/userHelper";
import { UpdateNameProfil } from "./updateName";
import { UpdateProfil } from "./updateProfil";
import { getPhoneValue } from "./../../utils/helpers/geoHelper";
import { UpdateSchool } from "./updateSchool";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { PAYS } from "../../countries";

export function CardProfil({ level = {}, user = {} }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  async function handleSave(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (!isEmpty(info.file.originFileObj)) {
      const uid = user.uid;
      const data = new FormData();
      data.append("uid", uid);
      data.append("file", info.file.originFileObj);
      setLoading(true);
      await userService
        .updatePhoto(data)
        .then((response) => {
          const data = response.data;
          userHelper.setData(data, dispatch);
        })
        .catch((reason) => {
          console.log(reason);
        });
      setLoading(false);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
  return (
    <Card className="yc-profil-card">
      <Layout
        style={{
          background: "#fff",
          padding: 10,
        }}
      >
        <Row align="middle" justify="center">
          <div style={{ position: "relative" }}>
            <div
              className={`yc-loader-avatar ${
                loading ? "yc-avatar-loading" : ""
              }`}
            >
              <Avatar shape="circle" size={150} src={user.photo}>
                <UserOutlined style={{ fontSize: "75px" }} />
              </Avatar>
              <div className="yc-profil-camera">
                <Upload
                  disabled={loading}
                  onChange={handleSave}
                  multiple={false}
                  accept="image/*"
                  isImageUrl={false}
                  showUploadList={false}
                >
                  <Button type="text" disabled={loading}>
                    <CameraFilled style={{ fontSize: "20px" }} />
                  </Button>
                </Upload>
              </div>
            </div>
          </div>
        </Row>
        <Typography.Title
          level={2}
          style={{ fontSize: 18, textAlign: "center", margin: 5 }}
        >
          {`${upperFirst(user.firstName)} ${upperCase(user.lastName)}`}
        </Typography.Title>
        <Divider />
        <Layout style={{ background: "#fff" }}>
          {userHelper.isStudent(user) && (
            <ProfilValue
              title="école"
              value={user.student?.establishment ?? ""}
              Icon={PartitionOutlined}
              updateComponent={<UpdateSchool user={user} />}
            />
          )}
          {userHelper.isStudent(user) && (
            <ProfilValue
              title="classe"
              value={levelHelper.getClass(level?.title, level?.groups)}
              Icon={TeamOutlined}
            />
          )}
          <ProfilValue
            title="Nom complet"
            value={`${upperFirst(user.firstName)} ${upperCase(user.lastName)}`}
            Icon={UserOutlined}
            updateComponent={<UpdateNameProfil user={user} />}
          />
          <ProfilValue
            title="Genre"
            value={`${
              user.gender === "f"
                ? "Femme"
                : user.gender === "m"
                ? "Homme"
                : "non spécifié"
            }`}
            Icon={UserOutlined}
          />
          <ProfilValue
            title="Pays"
            value={`${upperCase(
              PAYS.find(
                (p) =>
                  p.callingCodes.findIndex((x) => x === user.country) !== -1
              )?.translations?.fr
            )}`}
            Icon={FlagOutlined}
          />
          {userHelper.isStudent(user) === "g" && (
            <ProfilValue
              title="Adresse email"
              value={isEmpty(user.email) ? "non spécifié" : user.email}
              Icon={MailOutlined}
            />
          )}
          <ProfilValue
            title="Numéro de téléphone"
            value={
              getPhoneValue("+" + user.phone)
                ?.getNumber()
                ?.formatInternational() ?? `+${user.phone}`
            }
            Icon={PhoneOutlined}
          />
          {userHelper.isStudent(user) && (
            <React.Fragment>
              {/*<ProfilValue
              title="Progression"
              value=""
              rate={50.5}
              Icon={FundOutlined}
          />*/}
            </React.Fragment>
          )}
          {userHelper.isStudent(user) && (
            <ProfilValue
              title="Date de naissance"
              value={dateHelpers.getDate(user?.birthAt, "DD MMMM YYYY")}
              Icon={CalendarOutlined}
            />
          )}
          <Divider />
          <UpdateProfil user={user} />
        </Layout>
      </Layout>
    </Card>
  );
}

CardProfil.propTypes = {
  level: Prop.object,
  user: Prop.object,
};
