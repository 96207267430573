import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface fileData {
  profil: any;
  current: any;
  list: any[];
  documents: any[]; //show document about each subject
}

const initialState: fileData = {
  profil: {},
  current: {},
  list: [],
  documents: [],
};

export const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setFiles: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setDocuments: (state, action: PayloadAction<any[]>) => {
      state.documents = action.payload;
    },
  },
});
export const { setFile, setFiles, setDocuments } = fileSlice.actions;
export default fileSlice.reducer;
