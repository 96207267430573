import { toUpper, upperFirst } from "lodash";
import readingService from "../../services/readingService";
import dateHelpers from "./dateHelpers";
import { getUid } from "./title";

class ChildStat {
  private children: any[] = [];
  private periods: Date[] = [];

  constructor(children: any[], periods: Date[]) {
    this.children = children;
    this.periods = periods;
  }

  async getStats() {
    const response: any[] = [];
    const names: any[] = [];
    await names.push("Jours");
    await Promise.all(
      this.children.map((p: any) => {
        names.push(
          `${upperFirst(p?.student?.users?.firstName)} ${toUpper(
            p?.student?.users?.lastName
          )}`
        );
      })
    );
    await response.push(names);
    await Promise.all(
      this.periods.map(async (p: Date) => {
        const values: any[] = [];
        const date = await dateHelpers.getDate(p, "DD MMM");
        await values.push(date);
        await Promise.all(
          this.children.map(async (child: any) => {
            const uid = await getUid(child?.student["@id"] ?? "");
            const element = await this.getReadings(uid, p);
            await values.push(element);
          })
        );
        await response.push(values);
      })
    );
    return response;
  }

  private async getReadings(uid: string, date: Date) {
    let value = 0;
    const dateFormat = await dateHelpers.getDate(date, "YYYY-MM-DD");
    const query = `student=${uid}&createdAt[after]=${dateFormat}&createdAt[before]=${dateFormat}`;
    console.log("QUERY", query);
    await readingService
      .getByKey(query)
      .then((response) => {
        value = readingService.getTotal(response);
      })
      .catch(() => {
        value = 0;
      });
    return value;
  }
}

export default ChildStat;
