import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DlInput, DlRadio, DlSelect } from "../../components/input";
import * as yup from "yup";
import userService from "../../services/userService";
import { getUid } from "../../utils/helpers/title";
import { isEmpty, lowerCase, toString } from "lodash";
import userHelper from "../../utils/helpers/userHelper";
import { useDispatch } from "react-redux";
import { PAYS } from "./../../countries";
import dateHelpers from "../../utils/helpers/dateHelpers";

interface updateUserInterface {
  user: any;
}

export function UpdateProfil(props: updateUserInterface) {
  const user = props.user;

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [allCountries, setCountries] = React.useState<any>([]);
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      country: user?.country,
      gender: user?.gender,
      birthAt: user?.birthAt,
    },
    onSubmit: handleUpdate,
    validationSchema: yup.object({
      gender: yup.string().required("Champ requis"),
      country: yup.string().required("Champ requis"),
    }),
  });

  React.useEffect(() => {
    const { birthAt, country, gender } = user;
    formik.setValues({
      birthAt: isEmpty(birthAt)
        ? null
        : dateHelpers.getDate(birthAt, "YYYY-MM-DD"),
      country,
      gender,
    });
  }, [user]);

  React.useEffect(() => {
    let items: any = [];
    PAYS.forEach((p) => {
      items.push({
        value: p.callingCodes[0] ?? null,
        title: p.translations.fr,
      });
    });
    if (!isEmpty(search)) {
      items = items.filter(
        (p: any) => lowerCase(p.title).search(lowerCase(search)) !== -1
      );
    }
    items.unshift({ value: null, title: "Sélectionnez un pays" });
    setCountries(items);
  }, [search]);

  async function handleUpdate() {
    const data = formik.values;
    const uid = getUid(user["@id"]);
    setLoading(true);
    await userService
      .update(toString(uid), data)
      .then((response) => {
        const data = response.data;
        userHelper.setData(data, dispatch);
        notification.info({
          message: "Modifcation des informations du profil",
          description: "La modification a réussi",
        });
        setLoading(false);
        setVisible(false);
        setSearch("");
        formik.resetForm();
      })
      .catch((reason) => {
        console.log("reason", reason.response);
        notification.error({
          message: "Modification des infos",
          description: "La modificaton a échoué",
        });
      });
    setLoading(false);
  }
  function handleOpen() {
    setVisible(true);
  }
  function handleClose() {
    if (loading) {
      return;
    }
    setVisible(false);
  }
  return (
    <React.Fragment>
      <Button onClick={handleOpen} type="link">
        <i className="material-icons">edit</i> Modifier les autres informations
      </Button>
      {/*@ts-ignore this lib is incompatible with react18*/}
      <Modal
        visible={visible}
        onCancel={handleClose}
        okText="Modifier"
        cancelText="Annuler"
        centered
        onOk={() => formik.handleSubmit()}
        title="Modification multiple"
        confirmLoading={loading}
      >
        <div>
          <div>
            <DlRadio
              formik={formik}
              name="gender"
              list={[
                { value: "f", title: "Femme" },
                { value: "m", title: "Homme" },
              ]}
            />
          </div>
          <DlSelect
            formik={formik}
            name="country"
            list={allCountries}
            label="Votre pays"
            search
            setSearch={setSearch}
          />
          <div style={{ marginTop: 5 }}>
            <DlInput
              formik={formik}
              name="birthAt"
              label="Votre date de naissance"
              placeholder="votre date ici"
              type="date"
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
