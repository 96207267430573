import { Col, Layout, Row, Typography } from "antd";
import React from "react";
import MobileStoreButton from "react-mobile-store-button";

export function AppHome() {
  const iOSUrl =
    "https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8";
  return (
    <Layout style={{ padding: "20px" }}>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        {` Yeccolapp "Enseignant du futur" disponible sur`}
      </Typography.Title>
      <Row style={{ margin: "auto" }}>
        <Col>
          <div>
            <MobileStoreButton
              store="ios"
              url={iOSUrl}
              linkProps={{ title: "iOS Store Button" }}
            />
          </div>
        </Col>
        <Col>
          <div>
            <MobileStoreButton
              store="android"
              url={iOSUrl}
              linkProps={{ title: "iOS Store Button" }}
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
}
