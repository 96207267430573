import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface supportData {
  currentStudent: any;
  responsibles: any[]; //all support about student
  children: any[]; //all support about responsible
}

const initialState: supportData = {
  currentStudent: {},
  responsibles: [],
  children: [],
};

export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setStudentResponsibles: (state, action: PayloadAction<any[]>) => {
      state.responsibles = action.payload;
    },
    setResponsibleChildren: (state, action: PayloadAction<any[]>) => {
      state.children = action.payload;
    },
    setCurrentChild: (state, action: PayloadAction<any>) => {
      state.currentStudent = action.payload;
    },
  },
});
export const {
  setStudentResponsibles,
  setResponsibleChildren,
  setCurrentChild,
} = supportSlice.actions;
export default supportSlice.reducer;
