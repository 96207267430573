import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDashboardSubjects,
  setSubjectFilter,
  setSubjects,
} from "../redux/subjectReducer";
import lessonService from "../services/lessonService";
import subjectService from "../services/subjectService";
import supportService from "../services/supportService";
import loader from "../assets/images/loading.gif";
import { Typography } from "antd";
import userHelper from "../utils/helpers/userHelper";
import { RootState } from "../redux/store";
import {
  setResponsibleChildren,
  setStudentResponsibles,
} from "../redux/supportReducer";
import { getUid } from "../utils/helpers/title";
import { groupBy, isEmpty } from "lodash";
import subscriptionService from "../services/subscriptionService";
import { setAllUserSubscriptions } from "../redux/subscriptionReducer";
import fileService from "../services/fileService";
import commitmentService from "../services/commitmentService";
import {
  setStudentForegroundLessons,
  setStudentForegroundVideo,
  setStudentForegroundVideoPoster,
  setStudentReadings,
  setStudentTeachers,
} from "../redux/studentReducer";
import readingService from "../services/readingService";
import { setBlockUser } from "../redux/userReducer";
import searchHelper from "../utils/helpers/searchHelper";
import dateHelpers from "../utils/helpers/dateHelpers";
import { ResponsibleAdmin } from "./responsible";
import { TeacherAdmin } from "./teacher";
import { StudentAdmin } from "./student";
import studentHelper from "../utils/helpers/studentHelper";

interface splashInterface {
  loading: boolean;
  setLoading: any;
}

export function SplashScreen(props: splashInterface) {
  const { loading, setLoading } = props;
  const dispatch = useDispatch();
  const subjectFilter = useSelector((state: RootState) => state.subject.filter);

  const user = useSelector((state: RootState) => state.user.profil);
  const [studentResponsibles, setSplashStudentResponsibles] = React.useState<
    any[]
  >([]);
  const [studentTeachers, setSplashStudentTeachers] = React.useState<any[]>([]);

  React.useEffect(() => {
    init(user);
    const student = userHelper.getStudent(user);
    dispatch(setSubjectFilter({ level: student?.level?.uid }));
  }, [user]);

  React.useEffect(() => {
    searchSubjects();
  }, [subjectFilter]);

  React.useEffect(() => {
    if (userHelper.isStudent(user)) {
      checkUser();
    }
  }, [studentResponsibles, studentTeachers]);

  async function checkUser() {
    const phone = user.phone as string;
    const regex = /PHONE/;
    if (regex.test(phone)) {
      if (studentTeachers.length === 0 && studentResponsibles.length === 0) {
        dispatch(setBlockUser(true));
      }
    }
  }

  async function getStudentReadings(user: any) {
    const student = userHelper.getStudent(user);
    const query = `lesson.subject.level=${student?.level?.uid}&student=${student.uid}`;
    readingService
      .getByKey(query)
      .then((response) => {
        const data = readingService.getData(response);
        const dataGroup = groupBy(
          data,
          (value: any) => value.lesson.subject["@id"]
        );
        const elements = [];
        for (const key in dataGroup) {
          const element = dataGroup[key][0];
          const len = dataGroup[key].length;
          elements.push([element?.lesson?.subject?.title, len]);
        }
        elements.unshift(["Task", "Nombre"]);
        dispatch(setStudentReadings(elements));
        /*const elements = [];
      data.forEach((p:any)=>{
        elements.push([p.title, random(4, 18, false)]);
      })
      elements.unshift(["Task", "Nombre"]);*/
      })
      .catch((reason) => {
        console.warn(reason);
      });
  }

  async function searchSupports(user: any) {
    let query = ``;
    if (userHelper.isResponsible(user)) {
      let responsible = userHelper.getResponsible(user);
      if (responsible !== null) {
        responsible = responsible["@id"];
      } else {
        return;
      }
      query = `responsible=${getUid(
        responsible
      )}&order[student.users.firstName]=asc&limit=4`;
      await supportService
        .getByKey(query)
        .then((response) => {
          const data = supportService.getData(response);
          dispatch(setResponsibleChildren(data));
        })
        .catch((reason) => console.warn(reason));
    }
    if (userHelper.isStudent(user)) {
      let student = userHelper.getStudent(user);
      if (student !== null) {
        student = student["@id"];
      } else {
        return;
      }
      query = `student=${getUid(
        student
      )}&status[]=in_pending&status[]=validated&status[]=child_in_pending`;
      await supportService
        .getByKey(query)
        .then((response) => {
          const data = supportService.getData(response);
          setSplashStudentResponsibles(data);
          dispatch(setStudentResponsibles(data));
        })
        .catch((reason) => console.warn(reason));
    }
  }

  async function searchManagements(user: any) {
    let query = ``;
    if (userHelper.isTeacher(user)) {
      let teacher = userHelper.getTeacher(user);
      if (teacher !== null) {
        teacher = teacher["@id"];
      } else {
        return;
      }
      const data = await searchHelper.getTeacherManagement(user, dispatch);
      setSplashStudentTeachers(data);
    }
    if (userHelper.isStudent(user)) {
      let student = userHelper.getStudent(user);
      if (student !== null) {
        student = student["@id"];
      } else {
        return;
      }
      query = `student=${getUid(student)}`;
      await commitmentService
        .getByKey(query)
        .then((response) => {
          const data = commitmentService.getData(response);
          dispatch(setStudentTeachers(data));
        })
        .catch((reason) => console.warn(reason));
    }
  }

  async function getSubscriptions(uid: string) {
    const query = `user=${uid}`;
    await subscriptionService
      .getByKey(query)
      .then((response) => {
        const data = subscriptionService.getData(response);
        dispatch(setAllUserSubscriptions(data));
      })
      .catch((reason) => console.warn(reason));
  }

  async function searchSubjects() {
    let query = ``;
    for (const key in subjectFilter) {
      const value = subjectFilter[key as "title"];
      query += `${key}=${value}&`;
    }
    await subjectService
      .getByKey(query)
      .then((response) => {
        const data = subjectService.getData(response);
        dispatch(setSubjects(data));
      })
      .catch((reason) => console.warn(reason));
  }

  async function init(user: any) {
    const f = await userHelper.getSubscriptionState(dispatch);
    console.log("SUB", f);
    if (userHelper.isTeacher(user)) {
      await searchHelper.getTeacherLevels(user, dispatch);
    }
    if (userHelper.isResponsible(user) || userHelper.isStudent(user)) {
      await searchSupports(user);
    }
    if (userHelper.isTeacher(user) || userHelper.isStudent(user)) {
      await searchManagements(user);
    }
    await setTimeout(() => {
      console.log("wait");
    }, 1000);
    if (!isEmpty(user["@id"])) {
      await getSubscriptions(getUid(user["@id"]));
    }
    if (userHelper.isStudent(user)) {
      const subjects = await getSubjects();
      dispatch(setDashboardSubjects(subjects));
      await studentHelper.getDocuments(user, dispatch);
      getStudentReadings(user);
    }
    if (userHelper.isStudent(user)) {
      const student = userHelper.getStudent(user);
      await lessonService
        .getByKey(
          `subject.level=${student?.level?.uid}&order[foregroundTo]=asc&limit=3`
        )
        .then((response) => {
          const data = lessonService.getData(response);
          dispatch(setStudentForegroundLessons(data));
        })
        .catch((reason) => console.warn(reason));
      await fileService
        .getByKey(
          `lesson.subject.level=${student?.level?.uid}&typeMime=video&order[lesson.foregroundTo]=asc&limit=1`
        )
        .then(async (response) => {
          const data = fileService.getData(response);
          if (data.length > 0) {
            const item = data[0];
            const uid = getUid(item["@id"]);
            dispatch(setStudentForegroundVideo(item));
            await getVideoPoster(uid);
          }
        })
        .catch((reason) => console.warn(reason));
      await userHelper.setReading(dispatch);
    }
    if (userHelper.isTeacher(user)) {
      await getForecast();
    }
    setLoading(false);
  }

  async function getVideoPoster(uid: string) {
    await fileService
      .getByKey(`lesson=${uid}&typeMime=image&limit=1`)
      .then(async (response) => {
        const data = fileService.getData(response);
        if (data.length > 0) {
          const item = data[0];
          dispatch(setStudentForegroundVideoPoster(item));
        }
      })
      .catch((reason) => console.warn(reason));
  }

  async function getSubjects() {
    let items: any[] = [];
    const student = userHelper.getStudent(user);
    await subjectService
      .getByKey(`level=${student?.level?.uid}&order[title]=asc&limit=8`)
      .then((response) => {
        const data = subjectService.getData(response);
        items = data;
      })
      .catch((reason) => console.warn(reason));
    return items;
  }

  async function getForecast(commitments?: any[]) {
    const week = dateHelpers.getCurrentWeek();
    searchHelper.getWeekForecast(week, commitments, dispatch);
    await searchHelper.getForecast(commitments, dispatch);
  }

  React.useEffect(() => {
    if (loading) {
      window.document.body.style.height = "100vh";
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.height = "auto";
      window.document.body.style.overflow = "auto";
    }
  }, [loading]);

  return (
    <React.Fragment>
      {loading && (
        <div className="yc-splash">
          <img src={loader} alt="" />
          <Typography.Text
            style={{ position: "absolute", top: "50%", marginTop: 50 }}
          >
            {"Patientez pendant le chargement..."}
          </Typography.Text>
        </div>
      )}
      {userHelper.isResponsible(user) && <ResponsibleAdmin />}
      {userHelper.isStudent(user) && <StudentAdmin />}
      {userHelper.isTeacher(user) && <TeacherAdmin />}
    </React.Fragment>
  );
}
