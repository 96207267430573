import { Result } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { CreateChild } from "../screens/responsibility/createChild";
import userHelper from "../utils/helpers/userHelper";

const RenderStudent = () => {
  return (
    <div>
      <Result
        status="403"
        title="Vous n'avez pas un numéro de téléphone"
        subTitle="Désolé, vous n'êtes pas autorisé à accéder à cette page"
        extra={<CreateChild type="default" block isResponsible={false} />}
      />
    </div>
  );
};

const RenderOther = () => {
  return (
    <div>
      <Result
        status="403"
        title="Vous n'avez pas un numéro de téléphone"
        subTitle="Désolé, vous n'êtes pas autorisé à accéder à cette page"
      />
    </div>
  );
};

export function BlockScreen() {
  const isBlocked = useSelector((state: RootState) => state.user.toBlock);
  const user = useSelector((state: RootState) => state.user.profil);

  return (
    <React.Fragment>
      {isBlocked && (
        <div className="yc-splash">
          {userHelper.isStudent(user) && <RenderStudent />}
          {(userHelper.isTeacher(user) || userHelper.isResponsible(user)) && (
            <RenderOther />
          )}
        </div>
      )}
    </React.Fragment>
  );
}
