import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AdminLayout } from "./layouts/admin";
import { HomeLayout } from "./layouts/home";
import "antd/dist/antd.css";
import "./assets/scss/app.scss";
import { NotFoundScreen } from "./layouts/404";
import store from "./redux/store";
import { Provider } from "react-redux";
import { RootHome } from "./screens";
import { ConfigProvider, Result } from "antd";

const customizeRenderEmpty = () => (
  <div>
    <Result status="404" title="Aucune donnée n'est trouvée" />
  </div>
);

function App() {
  ConfigProvider.config({
    theme: {
      primaryColor: "red",
    },
  });
  return (
    <Provider store={store}>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <React.Fragment>
          <BrowserRouter>
            <Routes>
              <Route element={<AdminLayout />} path="admin/*" />
              <Route element={<HomeLayout />} path="home/*" />
              <Route path="/" element={<RootHome />} />
              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
          </BrowserRouter>
        </React.Fragment>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
