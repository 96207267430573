import { Layout } from "antd";
import React from "react";

export function FooterHome() {
  return (
    <Layout.Footer style={{ background: "#fff" }}>
      <div style={{ textAlign: "center" }}>
        {`Tout droit reservé`}{" "}
        <a
          href="http://dillhub.com"
          title="DILLHUB, la référence de développement informatique"
          target="_target"
        >
          {`Yeccolapp sarl`}
        </a>{" "}
        {`©2022`}
      </div>
    </Layout.Footer>
  );
}
