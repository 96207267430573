import { lazy } from "react";
import { RootHome } from "./screens";
import { ForecatView } from "./screens/forecatView";
const HomeScreen = lazy(() =>
  import("./screens/home").then(({ HomeScreen }) => ({ default: HomeScreen })),
);
import { LoginScreen } from "./screens/login";
import { PaymentScreen } from "./screens/payment";
import { PaymentListScreen } from "./screens/paymentList";
import { PrivacyScreen } from "./screens/privacyScreen";
import { ProfilScreen } from "./screens/profil";
//import { RegisterScreen } from "./screens/register";
import { ResponsibleScreen } from "./screens/responsible";
import { StudentResponsiblesScreen } from "./screens/studentResponsibles";
import { StudentTeachersScreen } from "./screens/studentTeachers";
import { RegisterBlockedScreen } from "./screens/registerBloqued";
const LessonScreen = lazy(() =>
  import("./screens/showLesson").then(({ LessonScreen }) => ({
    default: LessonScreen,
  })),
);
const SubjectScreen = lazy(() =>
  import("./screens/subjects").then(({ SubjectScreen }) => ({
    default: SubjectScreen,
  })),
);
const SubjectView = lazy(() =>
  import("./screens/subjectView").then(({ SubjectView }) => ({
    default: SubjectView,
  })),
);
const ColleagueScreen = lazy(() =>
  import("./screens/colleague").then(({ ColleagueScreen }) => ({
    default: ColleagueScreen,
  })),
);

const DocScreen = lazy(() =>
  import("./screens/docs").then(({ DocScreen }) => ({
    default: DocScreen,
  })),
);

const ChildView = lazy(() =>
  import("./screens/childView").then(({ ChildView }) => ({
    default: ChildView,
  })),
);

const ForecatScreen = lazy(() =>
  import("./screens/forecats").then(({ ForecatScreen }) => ({
    default: ForecatScreen,
  })),
);

declare type layoutType = "/admin" | "/home" | "/";
export declare type userType = "student" | "responsible" | "teacher";

export interface routeInterface {
  name: string;
  label: string;
  path: string;
  layout: layoutType;
  isMenu: boolean;
  component: any;
  icon: any | null;
  iconTransition?: any | null;
  parent?: string;
  visibility: userType[];
  subscribeable: boolean;
  description?: string;
  title?: string;
}

const routes: routeInterface[] = [
  {
    component: HomeScreen,
    icon: null,
    isMenu: true,
    label: "Accueil",
    layout: "/admin",
    name: "home",
    path: "/",
    iconTransition: null,
    visibility: ["responsible", "student", "teacher"],
    subscribeable: false,
    title: "Accueil",
  },
  {
    component: SubjectScreen,
    icon: null,
    isMenu: true,
    label: "Mes cours",
    layout: "/admin",
    name: "courses",
    path: "/subjects",
    iconTransition: null,
    visibility: ["student"],
    subscribeable: false,
    title: "Mes cours",
  },
  {
    component: HomeScreen,
    icon: null,
    isMenu: false,
    label: "Ouvrage",
    layout: "/admin",
    name: "elements",
    path: "/elements",
    iconTransition: null,
    visibility: ["responsible", "student", "teacher"],
    subscribeable: false,
    title: "Ouvrages",
  },
  {
    component: LoginScreen,
    icon: null,
    isMenu: false,
    label: "Connexion",
    layout: "/home",
    path: "/login",
    name: "login",
    iconTransition: null,
    visibility: ["responsible", "student", "teacher"],
    subscribeable: false,
    title: "Connexion",
  },
  {
    component: RegisterBlockedScreen,
    icon: null,
    isMenu: false,
    label: "Register",
    layout: "/home",
    path: "/register",
    name: "register",
    iconTransition: null,
    visibility: ["responsible", "student", "teacher"],
    subscribeable: false,
    title: "Inscription",
  },
  {
    component: PrivacyScreen,
    icon: null,
    isMenu: false,
    label: "Register",
    layout: "/home",
    path: "/privacy",
    name: "privacy",
    iconTransition: null,
    visibility: ["responsible", "student", "teacher"],
    subscribeable: false,
    title: "Termes et contrats",
  },
  {
    component: LessonScreen,
    icon: null,
    isMenu: false,
    label: "Lesson content",
    layout: "/admin",
    path: "/lessons/:id/view",
    name: "lesson",
    iconTransition: null,
    visibility: ["student", "teacher"],
    subscribeable: false,
    title: "Contenu de leçons",
  },
  {
    component: ProfilScreen,
    icon: null,
    isMenu: false,
    label: "Profil scrren",
    layout: "/admin",
    path: "/profil",
    name: "profil",
    iconTransition: null,
    visibility: ["responsible", "student", "teacher"],
    subscribeable: false,
    title: "Profil",
  },
  {
    component: RootHome,
    icon: null,
    isMenu: false,
    label: "accueil",
    layout: "/",
    path: "/",
    name: "home",
    iconTransition: null,
    visibility: ["responsible", "student", "teacher"],
    subscribeable: false,
    title: "Accueil général",
  },
  {
    component: SubjectView,
    icon: null,
    isMenu: false,
    label: "voir la matière",
    layout: "/admin",
    path: "/subjects/view",
    name: "home",
    iconTransition: null,
    visibility: ["student"],
    subscribeable: true,
    title: "Voir la matière",
  },
  {
    component: ColleagueScreen,
    icon: null,
    isMenu: true,
    label: "groupe d'étude",
    layout: "/admin",
    path: "/colleagues",
    name: "Colleague-screen",
    iconTransition: null,
    visibility: ["student"],
    subscribeable: false,
    title: "Groupe d'étude",
  },
  {
    component: ColleagueScreen,
    icon: null,
    isMenu: false,
    label: "Audio ou vidéo",
    layout: "/admin",
    path: "/multimedia",
    name: "file-video",
    iconTransition: null,
    visibility: ["student"],
    subscribeable: true,
    title: "Audio",
  },
  {
    component: ResponsibleScreen,
    icon: null,
    isMenu: true,
    label: "suivi",
    layout: "/admin",
    path: "/responsibility",
    name: "reponsible-teacher",
    iconTransition: null,
    visibility: ["responsible", "teacher"],
    subscribeable: false,
    title: "Suivi",
  },
  {
    component: ChildView,
    icon: null,
    isMenu: false,
    label: "child-view",
    layout: "/admin",
    path: "/child/view",
    name: "child-view",
    iconTransition: null,
    visibility: ["responsible", "teacher"],
    subscribeable: false,
    title: "Affichage des enfants",
  },
  {
    component: PaymentScreen,
    icon: null,
    isMenu: false,
    label: "payment",
    layout: "/admin",
    path: "/payment",
    name: "payment-screen",
    iconTransition: null,
    visibility: ["responsible", "teacher", "student"],
    subscribeable: false,
    title: "Paiement",
  },
  {
    component: StudentResponsiblesScreen,
    icon: null,
    isMenu: false,
    label: "responsables",
    layout: "/admin",
    path: "/responsibles",
    name: "student-responsible-screen",
    iconTransition: null,
    visibility: ["student"],
    subscribeable: false,
    title: "Responsables",
  },
  {
    component: StudentTeachersScreen,
    icon: null,
    isMenu: false,
    label: "teachers",
    layout: "/admin",
    path: "/teachers",
    name: "student-teacher-screen",
    iconTransition: null,
    visibility: ["student"],
    subscribeable: false,
    title: "Enseignants",
  },
  {
    component: ForecatScreen,
    icon: null,
    isMenu: true,
    label: "prévisions",
    layout: "/admin",
    path: "/forecats",
    name: "forecat-teacher-screen",
    iconTransition: null,
    visibility: ["teacher"],
    subscribeable: false,
    title: "Prévisions",
  },
  {
    component: ForecatView,
    icon: null,
    isMenu: false,
    label: "HJ",
    layout: "/admin",
    path: "/forecats/view/",
    name: "forecast-teacher-view",
    iconTransition: null,
    visibility: ["teacher"],
    subscribeable: true,
    title: "Prévisions",
  },
  {
    component: DocScreen,
    icon: null,
    isMenu: true,
    label: "YECCOTHEQUE",
    layout: "/admin",
    path: "/documents",
    name: "file-doc",
    iconTransition: null,
    visibility: ["student", "responsible", "teacher"],
    subscribeable: false,
    title: "YECCOTHEQUE",
  },
  {
    component: PaymentListScreen,
    icon: null,
    isMenu: false,
    label: "payment",
    layout: "/admin",
    path: "/payments",
    name: "payments",
    iconTransition: null,
    visibility: ["student", "responsible", "teacher"],
    subscribeable: false,
    title: "Paiement",
  },
];

export { routes };
