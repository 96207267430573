import { Button, Col, Row } from "antd";
import React from "react";
import userHelper from "../../utils/helpers/userHelper";

export function NavbarHome() {
  const isConnected = userHelper.isConnected();
  const [loading, setLoading] = React.useState(false);

  async function init() {
    setLoading(true);
    const isRefreshed = await userHelper.refreshUser();
    if (isRefreshed) {
      location.href = "/admin/";
    }
    setLoading(false);
  }

  return (
    <nav className="yc-index-navbar" id="home-navbar">
      <div className="yc-navbar-title">
        <a>
          <img
            style={{ width: "100px" }}
            src={require("../../assets/images/logo.png")}
            alt=""
          />
        </a>
      </div>
      <Row gutter={8}>
        {!isConnected && (
          <Col>
            <Button>
              <a href="/home/login">{"Se connecter"}</a>
            </Button>
          </Col>
        )}
        {!isConnected && (
          <Col className="yc-hidden-small">
            <Button type="primary">
              <a href="/home/register">{"S'inscrire"}</a>
            </Button>
          </Col>
        )}
        {isConnected && (
          <Col>
            <Button
              loading={loading}
              disabled={loading}
              onClick={init}
              type="primary"
            >
              {"Se lancer"}
            </Button>
          </Col>
        )}
      </Row>
    </nav>
  );
}
