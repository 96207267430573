import { Tabs } from "antd";
import React from "react";
import { CardProfil } from "./card";
import Prop from "prop-types";
import { ResponsibleProfil } from "./responsibles";
import { FolloWedLessonPofil } from "./followedLesson";
import { SubscriptionPofil } from "./subscriptionProfil";
import userHelper from "../../utils/helpers/userHelper";

export function DataProfil({ user = {}, type = "profil", level = {} }) {
  const [width, setWidth] = React.useState(window.innerWidth);
  console.log(type);

  window.addEventListener("resize", function () {
    setWidth(this.window.innerWidth);
    if (this.window.innerWidth > 991 && selectedIndex == "0") {
      //setSelectedIndex("1");
    }
  });

  const [selectedIndex, setSelectedIndex] = React.useState("0");
  function handleChange(index) {
    setSelectedIndex(index);
  }

  return (
    <Tabs
      tabPosition="top"
      style={{ minHeight: "70vh" }}
      onChange={handleChange}
      activeKey={selectedIndex}
      defaultActiveKey={selectedIndex}
    >
      {width <= 991 && (
        <Tabs.TabPane tab="Présentation" key="1" tabKey="1">
          <CardProfil level={level} user={user} />
        </Tabs.TabPane>
      )}
      {userHelper.isStudent(user) && (
        <Tabs.TabPane tab="Les cours suivis" key="0" tabKey="0">
          <FolloWedLessonPofil />
        </Tabs.TabPane>
      )}
      {userHelper.isStudent(user) && (
        <Tabs.TabPane tab="Responsables" tabKey="2" key="2">
          <ResponsibleProfil />
        </Tabs.TabPane>
      )}
      {userHelper.isResponsible(user) && (
        <Tabs.TabPane tab="Enfants" tabKey="3" key="3"></Tabs.TabPane>
      )}
      <Tabs.TabPane tab="Abonnement" tabKey="4" key="4">
        <SubscriptionPofil />
      </Tabs.TabPane>
    </Tabs>
  );
}

DataProfil.propTypes = {
  user: Prop.object,
  type: Prop.string,
  level: Prop.object,
};
