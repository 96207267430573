import { isEmpty } from "lodash";
import {
  setCurrentWeekForecats,
  setForecats,
  setForecatsCount,
} from "../../redux/forecatReducer";
import {
  setTeacherLevels,
  setTeacherStudents,
} from "../../redux/teacherReducer";
import commitmentService from "../../services/commitmentService";
import forecatService from "../../services/forecatService";
import managementService from "../../services/managementService";
import { getUid } from "./title";
import userHelper from "./userHelper";

class SearchHelper {
  async getTeacherManagement(user: any, dispatch?: any) {
    let data: any[] = [];
    const teacher = userHelper.getTeacher(user);
    const query = `teacher=${getUid(
      teacher["@id"],
    )}&status[]=in_pending&status[]=student_in_pending&status[]=validated`;
    await managementService
      .getByKey(query)
      .then((response) => {
        data = managementService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setTeacherStudents(data));
        }
      })
      .catch(() => (data = []));
    return data;
  }

  async getTeacherLevels(user: any, dispatch?: any) {
    let list: any[] = [];
    const teacher = userHelper.getTeacher(user);
    if (isEmpty(teacher)) {
      return list;
    }
    const uid = getUid(teacher["@id"]);
    await commitmentService
      .getByKey(`teacher=${uid}&status=in_progress`)
      .then((response) => {
        list = commitmentService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setTeacherLevels(list));
        }
      })
      .catch(() => {
        list = [];
      });
    return list;
  }

  async getForecast(commitments?: any[], dispatch?: any, page?: number) {
    let list: any[] = [];
    let query = `order[year]=asc&order[week]=asc&limit=5`;
    if (page !== undefined && page > 0) {
      query += `&page=${page}`;
    }
    if (!isEmpty(commitments)) {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();
      commitments?.forEach((p: any) => {
        const item = p?.level;
        let uid = "";
        if (!isEmpty(item)) {
          uid = getUid(item["@id"]);
        }
        query += `&subject.level[]=${uid}`;
      });
      query += `&limit=1000`;
      if (month >= 8) {
        query += `&year[]=${year}&year[]=${year + 1}`;
      } else {
        query += `&year[]=${year - 1}&year[]=${year}`;
      }
      await forecatService
        .getByKey(query)
        .then((response) => {
          const data = forecatService.getData(response);
          list = data;
          const count = forecatService.getTotal(response);
          dispatch(setForecats(data));
          dispatch(setForecatsCount(count));
        })
        .catch((reason) => {
          console.warn(reason);
        });
    }
    return list;
  }
  async getWeekForecast(week: number, commitments?: any[], dispatch?: any) {
    let query = `order[year]=asc&order[week]=asc&week=${week}`;
    if (!isEmpty(commitments)) {
      commitments?.forEach((p: any) => {
        const item = p?.level;
        let uid = "";
        if (!isEmpty(item)) {
          uid = getUid(item["@id"]);
        }
        query += `&subject.level[]=${uid}`;
      });
      await forecatService
        .getByKey(query)
        .then((response) => {
          const data = forecatService.getData(response);
          dispatch(setCurrentWeekForecats(data));
        })
        .catch((reason) => {
          console.warn(reason);
        });
    }
  }
}

export default new SearchHelper();
