import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface chapiterData {
  profil: any;
  current: any;
  list: any[];
}

const initialState: chapiterData = {
  profil: {},
  current: {},
  list: [],
};

export const chapiterSlice = createSlice({
  name: "chapiter",
  initialState,
  reducers: {
    setChapiter: (state, action: PayloadAction<any>) => {
      console.log("ACTION", action);
      state.current = action.payload;
    },
    setChapiters: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
  },
});
export const { setChapiter, setChapiters } = chapiterSlice.actions;
export default chapiterSlice.reducer;
