import { Layout, Table, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { PaymentColumns } from "../../utils/helpers/interface";
import { getPaymentTypeValue } from "../../utils/helpers/title";

export function AllPayments() {
  const subscriptions = useSelector((state: RootState) => state.user.payments);
  const [items, setItems] = React.useState<any>([]);

  React.useEffect(() => {
    const list: any = [];
    subscriptions.forEach((p) => {
      list.push({
        date: p.validatedAt
          ? dateHelpers.getDate(p.createdAt, "dddd DD MMMM YYYY")
          : "",
        amount: `${p.amount} ${p.currency ?? "$"}`,
        title: getPaymentTypeValue(p.type),
      });
    });
    setItems(list);
  }, [subscriptions]);

  return (
    <React.Fragment>
      <Layout className="yc-box" style={{ minHeight: "100vh" }}>
        <Typography.Title level={2}>{"Tous les paiements"}</Typography.Title>
        <Table
          className="yc-table"
          dataSource={items}
          columns={PaymentColumns}
        />
      </Layout>
    </React.Fragment>
  );
}
