import { Layout } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import userHelper from "../utils/helpers/userHelper";
import { ChildrenResponsible } from "./responsibility/children";
import { CreateChild } from "./responsibility/createChild";
import { CreateStudent } from "./responsibility/createStudentMan";
import { GraphicResponsible } from "./responsibility/graphic";
import { LevelResponsible } from "./responsibility/level";
import { SubjectResponsible } from "./responsibility/subjects";

export function ResponsibleScreen() {
  const user = useSelector((state: RootState) => state.user.profil);
  const children = useSelector((state: RootState) => state.support.children);
  const students = useSelector((state: RootState) => state.teacher.students);

  return (
    <React.Fragment>
      <Layout style={{ background: "#f1f1f1" }}>
        {userHelper.isResponsible(user) && (
          <GraphicResponsible items={children} />
        )}
        {userHelper.isTeacher(user) && <GraphicResponsible items={students} />}
        {userHelper.isResponsible(user) && <ChildrenResponsible />}
        {userHelper.isTeacher(user) && (
          <ChildrenResponsible items={students} type="teacher" />
        )}
        <SubjectResponsible />
        {userHelper.isTeacher(user) && <LevelResponsible />}
        <div style={{ position: "fixed", bottom: 20, right: 20 }}>
          {userHelper.isResponsible(user) && <CreateChild type="circle" />}
          {userHelper.isTeacher(user) && (
            <CreateStudent type="circle" isResponsible />
          )}
        </div>
      </Layout>
    </React.Fragment>
  );
}
